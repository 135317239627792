import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { Box, Typography, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

// styles
import useStyles from './styles';
import { getPublicDownloadableLink } from '../../../../../Helper';

function ContactComment({ comment }) {
  const styles = useStyles();
  
  return (
    <Box className={styles.commentContainer}>
      <Avatar 
        className={comment?.creatorProfileImg ? styles.avatar : styles.defaultAvatar}
        src={comment?.creatorProfileImg ? getPublicDownloadableLink(comment?.creatorProfileImg) : "/images/contacts/MaleAvatar.svg"}
      >
      </Avatar>
      <Box>
        <Typography
          className={styles.comment}
          variant='body1'
        >
          {comment?.comment}
        </Typography>

        <Box className={styles.commentDetails}>
          <Typography
            variant='body2'
            className={styles.commentName}
          >
            {comment?.creatorFullName}
          </Typography>
          <Typography
            variant='body2'
            className={styles.commentDate}
          >
            {comment?.createdOn ? moment(comment?.createdOn).format("D MMM YYYY") : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ContactComment.propTypes = {};

ContactComment.defaultProps = {};

export default ContactComment;
