import React, { useCallback, useReducer, useRef } from "react";
import { AutocompleteComponent, Inputs } from "../../../Components";
import { useTranslation } from "react-i18next";
import { GetLeads, OrganizationUserSearch } from "../../../Services";
import { LeadsStatusEnum } from "../../../Enums";

const ShareViaEmailForm = ({
  selected,
  setSelected,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const searchTimer = useRef(null);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [data, setData] = useReducer(reducer, {
    leads: [],
    systemUsers: [],
  });

  const getSystemUsers = async (value) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: value || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: "systemUsers",
          value: localValue,
        });
      } else {
        setData({
          id: "systemUsers",
          value: [],
        });
      }
    }
  };

  const getLeads = async (value) => {
    const response = await GetLeads({
      pageIndex: 0,
      pageSize: 10,
      search: value || "",
      leadStatus: LeadsStatusEnum.Open.status,
    });
    if (!(response && response.status && response.status !== 200))
      setData({ id: "leads", value: (response && response.result) || [] });
    else setData({ id: "leads", value: [] });
  };

  return (
    <div className="dialog-content-wrapper">
      <div className="dialog-content-item-parking  d-flex mx-2">
        <Inputs
          idRef="emailSubjectNoRef"
          labelValue="emailSubject"
          value={selected.emailSubject || ""}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            setSelected({
              id: "emailSubject",
              value: event.target.value,
            });
          }}
        />
      </div>
      <div className="dialog-content-item  d-flex mx-2">
        <Inputs
          idRef="emailBodyRef"
          labelValue="email-Body"
          value={selected.emailBody || ""}
          multiline
          rows={4}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            setSelected({ id: "emailBody", value: event.target.value });
          }}
        />
      </div>

      <fieldset className="styled-fieldset d-flex mx-3">
        <legend className="styled-legend">
          {t(`${translationPath}send-to`)}
        </legend>
        <div className="dialog-content-item d-flex-column-center">
          <AutocompleteComponent
            idRef="System-usersRef"
            inputPlaceholder={t(`${translationPath}select`)}
            labelValue={t(`${translationPath}System-users`)}
            selectedValues={selected?.recipient?.Email?.systemUsers || []}
            wrapperClasses="mr-2 my-2"
            data={data.systemUsers || []}
            chipsLabel={(option) => option.fullName || ""}
            withoutSearchButton
            multiple
            displayLabel={(option) => option.fullName || ""}
            renderOption={(option) =>
              ((option.userName || option.fullName) &&
                `${option.fullName} (${option.userName})`) ||
              ""
            }
            onChange={(event, newValue) => {
              const localRecipient = {
                ...(selected.recipient),
              };
              localRecipient.Email.systemUsers = newValue;
              
              setSelected({
                id: "recipient",
                value: localRecipient,
              });
            }}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length == 0)
                getSystemUsers();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getSystemUsers(value);
              }, 1200);
            }}
          />
                   <AutocompleteComponent
            idRef="leadsRef"
            inputPlaceholder={t(`${translationPath}select`)}
            labelValue={t(`${translationPath}leads`)}
            selectedValues={selected?.recipient?.Email?.leads || []}
            wrapperClasses="mr-2 my-2"
            data={data.leads || []}
            chipsLabel={(option) => option?.lead?.contact_name?.name || option?.lead?.company_name || ""}
            withoutSearchButton
            multiple
            displayLabel={(option) => option?.lead?.contact_name?.name || option?.lead?.company_name || ""}
            renderOption={(option) =>
              ((option.userName || option?.lead?.contact_name?.name || option?.lead?.company_name) &&
                `${option?.lead?.contact_name?.name || option?.lead?.company_name}`) ||
              ""
            }
            onChange={(event, newValue) => {
              const localRecipient = {
                ...(selected.recipient),
              };
              localRecipient.Email.leads = newValue;

              setSelected({
                id: "recipient",
                value: localRecipient,
              });
            }}
            onOpen={() => {
              if (data.leads && data.leads.length == 0) getLeads();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getLeads(value);
              }, 1200);
            }}
          />
        </div>
      </fieldset>
    </div>
  );
};

export default ShareViaEmailForm;
