import React, { useCallback, useEffect, useState, useRef } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent } from '../../../../../Components';
import { showError } from '../../../../../Helper';
import { ActivitiesRelatedToActivitiesTypeEnum } from '../../../../../Enums';
import {
  GetAllRoles,
  GetAllBranches,
  GetLookupItemsByLookupTypeName,
  GetAllActivityTypesWithPrerequisites,
  lookupItemsGetId,
} from '../../../../../Services';

export const SearchFieldComponent = ({
  item,
  parentTranslationPath,
  translationPath,
  activitiesTypesDetails,
  onValueChanged,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [helperText, setHelperText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  const lookups = useCallback(async (lookupId) => {
    setIsLoading(true);
    const result = await lookupItemsGetId({
      lookupTypeId: lookupId,
    });
    if (!(result && result.status && result.status !== 200)) {
      setItems(
        result.map((i) => ({
          id: i.lookupItemId,
          name: i.lookupItemName,
        }))
      );
    } else {
      setItems([]);
    }
    setIsLoading(false);
  }, []);

  const GetAllActivityTypesWithPrerequisitesAPI = useCallback(
    async (search) => {
      setIsLoading(true);
      const result = await GetAllActivityTypesWithPrerequisites(search || '');
      if (!(result && result.status && result.status !== 200)) {
        setItems(
          result.map((i) => ({
            id: i.activityTypeId,
            name: i.activityTypeName,
          }))
        );
      } else {
        setItems([]);
      }
      setIsLoading(false);
    },
    []
  );

  const GetLookupItemsByLookupTypeNameAPI = useCallback(async (searchBy) => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: searchBy,
      pageSize: 50,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setItems(
        res &&
          res.result &&
          res.result.map((i) => ({
            id: i.lookupItemId,
            name: i.lookupItemName,
          }))
      );
    } else {
      setItems([]);
    }
    setIsLoading(false);
  }, []);

  const GetAllBranchesAPI = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetAllBranches({
      pageSize: 1000,
      pageIndex: 0,
      search: search || '',
    });
    if (!(res && res.status && res.status !== 200 && res.result)) {
      setItems(
        res.result.map((i) => ({
          id: i.branchId,
          name: i.branchName,
        }))
      );
    } else {
      setItems([]);
    }
    setIsLoading(false);
  }, []);

  const GetAllRolesAPI = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetAllRoles(1, 1000, search);
    if (!(res && res.status && res.status !== 200 && res.result)) {
      setItems(
        res.result.map((i) => ({
          id: i.rolesId,
          name: i.rolesName,
        }))
      );
    } else {
      setItems([]);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (item.lookup && item.lookup !== '') lookups(item.lookup);
    else if (item.searchBy && item.searchBy === 'prerequisites')
      GetAllActivityTypesWithPrerequisitesAPI();
    else if (item.searchBy && item.searchBy === 'roles') GetAllRolesAPI();
    else if (item.searchBy && item.searchBy === 'branches') GetAllBranchesAPI();
    else if (item.searchBy === 'relatedTo') {
      const itemList = Object.values(ActivitiesRelatedToActivitiesTypeEnum)
        .filter((item) => [2, 3, 6].includes(item.key))
        .map((r) => ({
          id: r.key,
          name: r.value,
        }));
      setItems(itemList);
    } else if (item.searchBy === 'LeadQualification')
      GetLookupItemsByLookupTypeNameAPI('LeadQualification');
  }, []);

  return (
    <>
      <AutocompleteComponent
        key={`filter${item.fieldKey + 1}-${item.fieldKey}`}
        idRef={`filter${item.fieldKey + 1}-${item.fieldKey}`}
        multiple={item.multiple || false}
        labelClasses={(item.isRequired && 'Requierd-Color') || ''}
        inputPlaceholder={t('search')}
        value={activitiesTypesDetails && activitiesTypesDetails[item.fieldKey]}
        selectedValues={
          activitiesTypesDetails && activitiesTypesDetails[item.fieldKey]
        }
        data={item && item && item.enum && item.enum.length ? item.enum : items}
        inputClasses='inputs theme-form-builder'
        displayLabel={(option) => (option && option.name) || ''}
        chipsLabel={(option) => option.name || ''}
        onChange={(e, v) => {
          if (item.isRequired && item.multiple && v && v.length < 1) {
            showError(t(`${translationPath}please-fill-all-required-field`));
            setHelperText(
              t(`${translationPath}please-fill-all-required-field`)
            );
            return;
          } else if (item.isRequired && item.multiple && v && v.length > 0) {
            setHelperText('');
          } else if (item.isRequired && !v) {
            showError(t(`${translationPath}please-fill-all-required-field`));
            setHelperText(
              t(`${translationPath}please-fill-all-required-field`)
            );
            return;
          } else if (item.isRequired && v) {
            setHelperText('');
          }
          onValueChanged(v);
        }}
        onInputKeyUp={(e) => {
          const { value } = e.target;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            if (item.searchBy === 'prerequisites')
              GetAllActivityTypesWithPrerequisitesAPI(value);
            else if (item.lookup && item.lookup !== '') lookups(item.lookup);
            else if (item.searchBy === 'prerequisites')
              GetAllActivityTypesWithPrerequisitesAPI();
            else if (item.searchBy === 'roles') GetAllRolesAPI(value);
            else if (item.searchBy && item.searchBy === 'branches')
              GetAllBranchesAPI(value);
          }, 700);
        }}
        isLoading={isLoading}
        withLoader
        withoutSearchButton
        labelValue={t(`${translationPath}${item.fieldTitle}`)}
        filterOptions={(options) => options}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        isWithError
        helperText={helperText}
        error={helperText !== ''}
      />
    </>
  );
};
