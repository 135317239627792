import React, {
  Component,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  ActionsButtonsComponent,
  Spinner,
  Tables,
} from "../../../../Components";
import DNCRDialog from "./Components/DNCRDialog";
import ConsentManagmentDialog from "./Components/ConsentManagmentDialog";
import { useTranslation } from "react-i18next";
import { getIsAllowedPermission, GetParams } from "../../../../Helper";
import {
  GetConsentLogsByContactId,
  GetLookupItemsByLookupTypeName,
} from "../../../../Services";
import DNCRAndConsentTableFilters from "./Components/DNCRAndConsentTableFilters";
import { ContactsPermissions } from "../../../../Permissions";
import { ActionsButtonsEnum, TableActions } from "../../../../Enums";
import { useSelector } from "react-redux";
import EvidenceDialog from "./Components/EvidenceDialog";
import moment from "moment";

function DNCRAndConsentManagmentView() {
  const parentTranslationPath = "DNCRAndConsentManagment";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const contactId = GetParams("id");
  const [isDialogOpen, setIsDialogOpen] = useReducer(reducer, {
    DNCRDialog: false,
    ConsentManagmentDialog: false,
    evidenceDialog: false,
  });
  const [activeAction, setActiveAction] = useState(ActionsButtonsEnum[1]);
  const [activeItem, setActiveItem] = useState(null);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [consentTypes, setConsentTypes] = useState([]);
  const [filter, setFilter] = useReducer(reducer, {
    startDate: null,
    endDate: null,
    key: "selection",
    user: null,
    remarks: null,
    dncrId: null,
    changeReasonId: null,
    mainConsentId: null,
    assetsConsentId: null,
    contactConsentEvidenceType: null,
    changeType: null,
  });

  const GetConsentLogs = async () => {
    setIsLoading(true);
    const res = await GetConsentLogsByContactId(contactId, {
      ...filter,
      fromDate: filter.fromDate || filter.startDate,
      toDate: filter.endDate || filter.toDate,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else setData(res);
    setIsLoading(false);
  };

  const getTableActions = () => {
    const actions = [
      {
        enum: TableActions.preview.key,
        isDisabled: false,
        externalComponent: null,
      },
    ];
    return actions;
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.preview.key) {
        setActiveItem(item);
        setIsDialogOpen({ id: "evidenceDialog", value: true });
      }
    },
    []
  );

  const onActionButtonChanged = (activeAction) => {
    setActiveAction(activeAction);
  };

  const onActionsButtonClicked = () => {
    if (activeAction === ActionsButtonsEnum[31].id) {
      setIsDialogOpen({ id: "DNCRDialog", value: true });
    } else if (activeAction === ActionsButtonsEnum[30].id) {
      setIsDialogOpen({ id: "ConsentManagmentDialog", value: true });
    }
  };

  const onDNCRCloseClicked = () => {
    setIsDialogOpen({ id: "DNCRDialog", value: false });
  };

  const onConsentManagmentDialogCloseClicked = () => {
    setIsDialogOpen({ id: "ConsentManagmentDialog", value: false });
  };

  const onEvidenceDialogCloseClicked = () => {
    setIsDialogOpen({ id: "evidenceDialog", value: false });
  };

  const getConsentTypes = useCallback(async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "ContactConsent",
      pageIndex: 0,
      pageSize: 99999,
    });

    if (!(res && res.status && res.status !== 200)) {
      setConsentTypes(res);
    } else setConsentTypes([]);
  }, []);

  useEffect(() => {
    getConsentTypes();
  }, []);

  useEffect(() => {
    GetConsentLogs();
  }, [filter, reload]);

  return (
    <div className="associated-contacts-wrapper childs-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="my-4">
        <ActionsButtonsComponent
          isDisabled={
            activeAction === ActionsButtonsEnum[1] ||
            activeAction === ActionsButtonsEnum[1].id
          }
          enableDNCR
          enableConsentManagment
          onActionsButtonClicked={onActionsButtonClicked}
          onActionButtonChanged={onActionButtonChanged}
        />
      </div>

      <div>
        <DNCRAndConsentTableFilters
          filter={filter}
          setFilter={setFilter}
          consentTypes={consentTypes}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>

      <Tables
        data={data || []}
        headerData={[
          {
            id: 1,
            label: "user",
            input: "user",
          },
          {
            id: 2,
            label: "date",
            input: "createdOn",
            isDate: true,
          },
          {
            id: 2,
            label: "time",
            component: (item) => (
              <span>
                {item?.createdOn ? moment(item.createdOn).format("LT") : ""}
              </span>
            ),
          },
          {
            id: 3,
            label: "change-type",
            component: (item) => (
              <span>
                {item?.changeType ? t(`${item.changeType.toLowerCase()}`) : ""}
              </span>
            ),
          },
          {
            id: 4,
            label: "reason-for-change",
            input: "changeReasonName",
          },
          {
            id: 5,
            label: "dncr-type",
            input: "dncrName",
          },
          {
            id: 6,
            label: "main-consent",
            input: "mainConsentName",
          },
          {
            id: 7,
            label: "asset-consent",
            input: "assetsConsentName",
          },
          {
            id: 8,
            label: "remarks",
            input: "remarks",
          },
        ]}
        activePage={0}
        defaultActions={getTableActions()}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        totalItems={data?.length || 200}
        itemsPerPage={data?.length || 10}
      />

      {isDialogOpen.DNCRDialog && (
        <DNCRDialog
          isOpen={isDialogOpen.DNCRDialog}
          onClose={onDNCRCloseClicked}
          reload={reload}
          setReload={setReload}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isDialogOpen.ConsentManagmentDialog && (
        <ConsentManagmentDialog
          isOpen={isDialogOpen.ConsentManagmentDialog}
          onClose={onConsentManagmentDialogCloseClicked}
          reload={reload}
          setReload={setReload}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isDialogOpen.evidenceDialog && (
        <EvidenceDialog
          isOpen={isDialogOpen.evidenceDialog}
          activeItem={activeItem}
          onClose={onEvidenceDialogCloseClicked}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
}

export default DNCRAndConsentManagmentView;
