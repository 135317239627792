export const FormsIdsEnum = {
  contactsIndividual: {
    id: 1,
    name: 'individual',
    page: 'contacts'
  },
  contactsCorporate: {
    id: 2,
    name: 'corporate',
    page: 'contacts'
  },
  properties: {
    id: 3,
    name: 'properties',
    page: 'properties'
  },
  units: {
    id: 14,
    name: 'units',
    page: 'units'
  },
  leadsOwner: {
    id: 6,
    name: 'owner',
    page: 'leads'
  },
  leadsSeeker: {
    id: 7,
    name: 'seeker',
    page: 'leads'
  },
  activities: {
    id: 8,
    page: 'activities'
  }
};
