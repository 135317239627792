import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import {
  // CheckboxesComponent,
  PaginationComponent,
  RadiosGroupComponent,
  ViewTypes,
  Spinner, 
} from '../../../../../../Components';
import { LeadProfileUnitsCardComponent, LeadUnitsTable } from './Sections';
import {
  bottomBoxComponentUpdate,
  GetParams,
  GlobalTranslate,
  showError,
  showSuccess,
  showWarn,
  GlobalHistory,
} from '../../../../../../Helper';
import { UnitsOperationTypeEnum } from '../../../../../../Enums';
import {
  GetAllMatchingUnitsByLeadId,
  SendUnitProposalToLeadAPI,
  leadDetailsGet,
} from '../../../../../../Services';
import { ViewTypesEnum } from '../../../../../../Enums/ViewTypes.Enum';
import { UnitsStatusEnum } from '../../../../../../Enums';
import { config } from '../../../../../../config';
import SearchAndMatchDialog from '../../../../LeadsLeaseView/LeadsLeaseUtilities/Dialogs/SearchAndMatchDialog/SearchAndMatchDialog';
import { StaticLookupsIds } from '../../../../../../assets/json/StaticLookupsIds';

export const LeadProfileUnitsComponent = ({
  parentTranslationPath,
  translationPath,
  leadWithPolicy,
  isLease,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [leadClass, setLeadClass] = useState(null);
  const [leadEmail, setLeadEmail] = useState(null);
  const [searchDialog, setSearchDialog] = useState(false);

  const [unitData, setUnitData] = useState({
    result: [],
    totalCount: 0,
  });
  const [selectedMatchesIds, setSelectedMatchesIds] = useState([]);
  const [selectedMatchesIndexes, setSelectedMatchesIndexes] = useState([]);
  const [units, setUnits] = useState({
    result: [],
    totalCount: 0,
  });

  const [canNewAddInquiry, setCanNewAddInquiry] = useState(null);
  const [closedLead, setClosedLead] = useState(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const GetAllMatchingByLeadId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllMatchingUnitsByLeadId(
      +GetParams('id'),
      filter.pageIndex + 1,
      filter.pageSize
    );

    if (!(result && result.status && result.status !== 200)) {
      setUnitData({
        result: result.result,
        totalCount: result.totalCount,
      });
      setCanNewAddInquiry(result && result.totalCount ? 'hide' : 'display');
    } else {
      setCanNewAddInquiry('display');
      setUnitData({
        result: [],
        totalCount: 0,
      });
    }

    setIsLoading(false);
  }, [filter, leadClass]);

  const GetLeadClass = useCallback(async () => {
    const leadId = +GetParams('id');
    const result = await leadDetailsGet({ id: leadId });
    if (!(result && result.status && result.status !== 200)) {
      setLeadClass(result && result.lead && result.lead.leadClass);
      setLeadEmail(result && result.contactEmailAddress);
      localStorage.setItem(
        'leadDetails',
        JSON.stringify(result && result.lead)
      );
      setClosedLead(
        (result &&
          result.lead &&
          result.lead.status &&
          result.lead.status.lookupItemName) ||
          null
      );
    } else {
      setLeadClass(null);
      setLeadEmail(null);
      setClosedLead(null);
    }
  }, []);

  useEffect(() => {
    if (leadClass) GetAllMatchingByLeadId();
  }, [filter, leadClass, leadEmail]);

  useEffect(() => {
    GetLeadClass();
  }, [GetLeadClass]);

  const readUnitDataDependsOnLeadClass = (
    unitData,
    oprationType,
    leadClass,
    item
  ) => {
    let status;
    if (
      (oprationType === UnitsOperationTypeEnum.sale.key ||
        oprationType === UnitsOperationTypeEnum.rentAndSale.key) &&
      (leadClass === 'Buyer' || leadClass === 'Seller')
    ) {
      for (const el in UnitsStatusEnum) {
        if (UnitsStatusEnum[el].name === (unitData && unitData.status))
          status = UnitsStatusEnum[el];
      }
      return {
        status: status,
        listing_agent:
          (unitData && unitData.listing_agent && unitData.listing_agent.name) ||
          null,
        leadOwner:
          (unitData && unitData.lead_owner && unitData.lead_owner.name) || null,
        isForSale: true,
        price:
          unitData.selling_price_agency_fee &&
          unitData.selling_price_agency_fee.salePrice,
      };
    } else if (
      (oprationType === UnitsOperationTypeEnum.rent.key ||
        oprationType === UnitsOperationTypeEnum.rentAndSale.key) &&
      (leadClass === 'Tenant' || leadClass === 'Landlord')
    ) {
      for (const el in UnitsStatusEnum) {
        if (UnitsStatusEnum[el].key === (item && item.leaseStatus))
          status = UnitsStatusEnum[el];
      }
      return {
        status: status,
        listing_agent:
          (unitData &&
            unitData.rent_listing_agent &&
            unitData.rent_listing_agent.name) ||
          null,
        leadOwner:
          (unitData &&
            unitData.lease_lead_owner &&
            unitData.lease_lead_owner.name) ||
          null,
        isForSale: false,
        price: unitData.rent_price_fees && unitData.rent_price_fees.rentPerYear,
      };
    } else {
      for (const el in UnitsStatusEnum) {
        if (UnitsStatusEnum[el].name === (unitData && unitData.status))
          status = UnitsStatusEnum[el];
      }
      return {
        status: status,
        listing_agent:
          (unitData && unitData.listing_agent && unitData.listing_agent.name) ||
          null,
        leadOwner:
          (unitData && unitData.lead_owner && unitData.lead_owner.name) || null,
        isForSale: true,
        price:
          (unitData.selling_price_agency_fee &&
            unitData.selling_price_agency_fee.salePrice) ||
          null,
      };
    }
  };

  useEffect(() => {
    if (unitData && unitData.result) {
      setUnits({
        result: unitData.result.map((item) => {
          const { unit } = item;
          const operationType = unit.operation_type.lookupItemId;
          const viewUnitDataByLeadClass = readUnitDataDependsOnLeadClass(
            unit,
            operationType,
            leadClass,
            item
          );
          return {
            createdOn: item.createdOn,
            unitImage: item.unitImage,
            owner: unit.owner,
            leadUnitStatus:
              viewUnitDataByLeadClass && viewUnitDataByLeadClass.status,
            status: viewUnitDataByLeadClass && viewUnitDataByLeadClass.status,
            leadOwner:
              (viewUnitDataByLeadClass && viewUnitDataByLeadClass.leadOwner) ||
              'N/A',
            isForSale:
              (viewUnitDataByLeadClass && viewUnitDataByLeadClass.isForSale) ||
              false,
            listing_agent:
              (viewUnitDataByLeadClass &&
                viewUnitDataByLeadClass.listing_agent) ||
              'N/A',
            price:
              (viewUnitDataByLeadClass && viewUnitDataByLeadClass.price) ||
              'N/A',

            unitId: item.unitId,
            unit_type_id: item.unit.unit_type_id,
            unitName: `${
              (unit.unit_type && unit.unit_type.lookupItemName) || ''
            } ${
              unit.property_name
                ? unit.property_name.name || unit.property_name
                : ''
            } ${unit.unit_number ? unit.unit_number : ''}`,
            refNumber: `${(unit.unit_ref_no && unit.unit_ref_no) || 'N/A'}`,
            operationType,
            unitOperationType:
              (operationType === UnitsOperationTypeEnum.rent.key ||
                operationType === UnitsOperationTypeEnum.rentAndSale.key) &&
              (GetParams('leadClass') === 'Tenant' ||
                GetParams('leadClass') === 'Landlord')
                ? GlobalTranslate.t('Shared:actions-buttons.rent')
                : GlobalTranslate.t('Shared:actions-buttons.sale'),
            unitStatus:
              operationType === UnitsOperationTypeEnum.rent.key
                ? GlobalTranslate.t('Shared:actions-buttons.rent')
                : GlobalTranslate.t('Shared:actions-buttons.sale'),
            unitStatussrent: GlobalTranslate.t('Shared:actions-buttons.rent'),
            unitStatussale: GlobalTranslate.t('Shared:actions-buttons.sale'),
            Pricesale:
              (unit.selling_price_agency_fee &&
                unit.selling_price_agency_fee.salePrice) ||
              'N/A',
            pricerent:
              (unit.rent_price_fees && unit.rent_price_fees.rentPerYear) ||
              'N/A',
            flatContent: [
              {
                iconClasses: 'mdi mdi-bed-outline',
                title: null,
                value: unit.bedrooms ? unit.bedrooms : 'N/A',
                value: unit.bedrooms
                  ? unit.bedrooms
                  : GlobalTranslate.t('Shared:any'),
              },
              {
                iconClasses: 'mdi mdi-shower',
                title: null,
                value: unit.bathrooms
                  ? unit.bathrooms
                  : GlobalTranslate.t('Shared:any'),
              },
              {
                iconClasses: 'mdi mdi-ruler-square',
                title: 'sqf',
                value: unit.builtup_area_sqft ? unit.builtup_area_sqft : 'N/A',
              },
              {
                iconClasses: 'mdi mdi-broom',
                title: '',
                value: unit.maid_rooms ? unit.maid_rooms : 'N/A',
              },
            ],
            details: [
              {
                iconClasses: 'mdi mdi-domain',
                title: 'unit-type',
                value: `${
                  (unit.unit_type && unit.unit_type.lookupItemName) || 'N/A'
                }`,
              },
              {
                iconClasses: 'mdi mdi-point-of-sale',
                title: 'frnished',
                value: `${unit.furnished || 'N/A'}`,
              },
              {
                iconClasses: 'mdi mdi-point-of-sale',
                title: 'listing_agent',
                value: `${
                  (viewUnitDataByLeadClass &&
                    viewUnitDataByLeadClass.listing_agent) ||
                  'N/A'
                }`,
              },
              {
                iconClasses: 'mdi mdi-point-of-sale',
                title: 'Views',
                value: `${
                  (unit.primary_view &&
                    unit.primary_view.map((el) => `${el.lookupItemName}, `)) ||
                  'N/A'
                }`,
              },
              {
                iconClasses: 'mdi mdi-point-of-sale',
                title:
                  viewUnitDataByLeadClass && viewUnitDataByLeadClass.isForSale
                    ? 'Owner'
                    : 'Owner',
                value: `${
                  (viewUnitDataByLeadClass &&
                    viewUnitDataByLeadClass.leadOwner) ||
                  'N/A'
                }`,
              },
            ],
          };
        }),
        totalCount: (unitData && unitData.totalCount) || 0,
      });
    }
  }, [unitData]);

  const cardCheckboxClicked = (item, index) => {
    if (leadEmail === null || leadEmail === 'N/A' || leadEmail === '')
      showWarn(t(`${t('Shared:email-address-missing')}`));
    else {
      setSelectedMatchesIds(() => {
        const cardIndex = selectedMatchesIds.findIndex(
          (element) => item.unitId === element
        );
        if (cardIndex !== -1) selectedMatchesIds.splice(cardIndex, 1);
        else selectedMatchesIds.push(item.unitId);
        return [...selectedMatchesIds];
      });
      setSelectedMatchesIndexes(() => {
        const cardIndex = selectedMatchesIndexes.findIndex(
          (element) => index === element
        );
        if (cardIndex !== -1) selectedMatchesIndexes.splice(cardIndex, 1);
        else selectedMatchesIndexes.push(index);
        return [...selectedMatchesIndexes];
      });
    }
  };
  const discardHandler = () => {
    setSelectedMatchesIds([]);
    setSelectedMatchesIndexes([]);
  };
  const sendSelectedMatchedHandler = useCallback(async () => {
    setIsLoading(true);

    const leadId = +GetParams('id');

    if (leadEmail === null || leadEmail === 'N/A' || leadEmail === '') {
      showWarn(t(`${t('Shared:email-address-missing')}`));
      setIsLoading(false);
      return;
    }
    if (leadId && selectedMatchesIds && selectedMatchesIds.length) {
      const result = await SendUnitProposalToLeadAPI(
        [leadId],
        selectedMatchesIds,
        null,
        config.SalesUnitProposalTemplateId,
        config.SendKey,
        config.server_name
      );
      if (!(result && result.status && result.status !== 200))
        showSuccess(t`${translationPath}selected-matches-sent-successfully`);
      else showError(t`${translationPath}selected-matches-sending-failed`);
    }
    setIsLoading(false);
  });

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const [activeActionType, setActiveActionType] = useState(
    ViewTypesEnum.cards.key
  );
  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );

  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='bottom-box-two-sections'>
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={units.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
        <div className='d-flex-v-center flex-wrap'>
          <ButtonBase
            className='btns theme-transparent mb-2'
            onClick={discardHandler}
          >
            <span>{t(`${translationPath}discard-selected`)}</span>
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid mb-2'
            onClick={sendSelectedMatchedHandler}
            disabled={!(selectedMatchesIds && selectedMatchesIds.length)}
          >
            <span>{t(`${translationPath}send-selected-matches`)}</span>
          </ButtonBase>
        </div>
      </div>
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  return (
    <div className='lead-profile-units-wrapper px-0 pt-0 childs-wrapper'>
      <div className='title-box-wrapper'>
        <div className='title-box-section'></div>
      </div>
      <div className='lead-profile-filter-section'>
        <div className='filter-section-item px-0'>
          <Spinner isActive={isLoading} isAbsolute />

          <div>
            {canNewAddInquiry &&
              canNewAddInquiry === 'display' &&
              closedLead !== 'Closed' && (
                <ButtonBase
                  className='btns theme-solid'
                  idRef={`AddNewZeroMatching`}
                  onClick={() => {
                    const leadId = +GetParams('id');
                    if (leadClass && leadClass === 'Tenant')
                      GlobalHistory.push(
                        `/home/zero-matching-lease/add?formType=2&leadId=${leadId}`
                      );
                    else if (leadClass && leadClass === 'Buyer')
                      GlobalHistory.push(
                        `/home/zero-matching-sale/add?formType=1&leadId=${leadId}`
                      );
                  }}
                >
                  <span>{t(`${translationPath}add-new-zero-matching`)}</span>
                </ButtonBase>
              )}
            {
              <ButtonBase
                className='btns theme-solid'
                onClick={() => setSearchDialog(true)}
              >
                <span>
                  {t(`utilities.cardDetailsComponent.search-and-match`, {
                    ns: 'LeadsView',
                  })}
                </span>
              </ButtonBase>
            }
          </div>

          <div>
            <ViewTypes
              onTypeChanged={onTypeChanged}
              activeTypes={[
                ViewTypesEnum.tableView.key,
                ViewTypesEnum.cards.key,
              ]}
              className='mb-3'
            />
          </div>
        </div>
      </div>
      {units &&
        units.result &&
        activeActionType === ViewTypesEnum.cards.key && (
          <LeadProfileUnitsCardComponent
            data={units}
            selectedMatchesIds={selectedMatchesIds}
            parentTranslationPath='UnitsView'
            translationPath={translationPath}
            onCardCheckboxClick={cardCheckboxClicked}
          />
        )}
      {units &&
        units.result &&
        activeActionType === ViewTypesEnum.tableView.key && (
          <LeadUnitsTable
            data={(unitData && unitData.result) || []}
            filter={filter}
            totalCount={(unitData && unitData.totalCount) || 0}
            parentTranslationPath='UnitsView'
            translationPath={translationPath}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            selectedMatchesIndexes={selectedMatchesIndexes}
            selectedMatchesIds={selectedMatchesIds}
            setSelectedMatchesIds={setSelectedMatchesIds}
            setSelectedMatchesIndexes={setSelectedMatchesIndexes}
            leadEmail={leadEmail}
          />
        )}
      {searchDialog && (
        <SearchAndMatchDialog
          open={searchDialog}
          onClose={() => setSearchDialog(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeData={leadWithPolicy.lead}
          operationType={isLease? StaticLookupsIds.Rent : StaticLookupsIds.Sales}
          leadId={+GetParams('id')}
        />
      )}
    </div>
  );
};
