import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  RadiosGroupComponent, AutocompleteComponent, Inputs
} from '../../../../../../Components';
import { GetAllBranches, GetTeam } from '../../../../../../Services';

export const SchemaSetting = ({
    parentTranslationPath,
    translationPath,
    state,
    onStateChanged,
    schema,
    isSubmitted,
    rotationEdit,
    onScemaChanged,
    currentSelected,
    setCurrentSelected
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [ResponseTeam, SetResponseTeam] = useState([]);
    const [loadings, setloading] = useState(false);
    const [loadingsTeam, setloadingsTeam] = useState(false);
    const filter = {
        pageSize: 25,
        pageIndex: 0,
        branchName: '',
        filterBy: null,
        orderBy: null,

    };
    const searchTimer = useRef(null);
    const [branches, setBranches] = useState({
        result: [],
        totalCount: 0
    });
    const getAllBranches = useCallback(async (value) => {
        setloading(true);
        const res = await GetAllBranches({
            ...filter, pageSize: 25, pageIndex: 1, branchName: value
        });
        if (!(res && res.status && res.status !== 200)) {
            setBranches({
                result: res.result,
                totalCount: res.totalCount
            });
        } else {
            setBranches({
                result: [],
                totalCount: 0
            });
        }

        setloading(false);
    }, [filter]);

    const GetMyTeam = async (searchItem) => {
        setloadingsTeam(true);
        const res = await GetTeam(1, 25, (searchItem || ''));

        if (!(res && res.status && res.status !== 200))
            SetResponseTeam(res);

        else
            SetResponseTeam([]);

        setloadingsTeam(false);
    };
    useEffect(() => {
        getAllBranches();
        GetMyTeam();
    }, []);


    return (
      <>
        <div className='schema-setting-wrapper p-4'>

          <div className='count-base-wrapper'>
            <span>{t(`${translationPath}count-based-on`)}</span>
            <div className='switch-btns'>

              <RadiosGroupComponent
                data={[
                                { value: '3', label: 'overAll-syetem' },
                                { value: '1', label: 'current-schema-assigned' },
                                { value: '2', label: 'current-schema-acitve-leads' },
                                { value: '4', label: 'one-by-one' },
                            ]}
                idRef='Actions'
                onSelectedRadioChanged={(e) => {
                                const localNewValue = {
                                    id: 'rotationSchemeLeadDistributionType',
                                    value: e.target.value
                                };
                                onStateChanged(localNewValue);
                            }}
                value={state && state.rotationSchemeLeadDistributionType && (state && state.rotationSchemeLeadDistributionType).toString()}
                name='Active'
                labelInput='label'
                valueInput='value'
                themeClass='theme-line'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />

            </div>
          </div>
          <div className='override-wrapper mt-3'>
            <span>{t(`${translationPath}cap-based-on`)}</span>
            <RadiosGroupComponent
              data={[
                            { value: false, label: 'OVERALL-SYSTEM' },
                            { value: true, label: 'CURENT-SCHEMA' },
                        ]}
              idRef='Actions'
              onSelectedRadioChanged={(e) => {
                            const localNewValue = {
                                id: 'isOverrideLeadCapacitySettingEnabled',
                                value: JSON.parse(e.target.value)
                            };
                            onStateChanged(localNewValue);
                        }}
              value={state.isOverrideLeadCapacitySettingEnabled}
              name='Active'
              labelInput='label'
              valueInput='value'
              themeClass='theme-line'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <AutocompleteComponent
            idRef='branchName-ToRef'
            labelValue='branchName'
            selectedValues={currentSelected?.rotationSchemeBranches || []}
            multiple
            isDisabled={(currentSelected?.rotationSchemeTeams 
                && currentSelected?.rotationSchemeTeams.length > 0)}
            data={branches.result || []}
            chipsLabel={(option) => option.branchName || ''}
            displayLabel={(option) => t(`${(option.branchName) || ''}`)}
            getOptionSelected={(option) => (currentSelected?.rotationSchemeBranches && 
               currentSelected.rotationSchemeBranches.findIndex((item) => item && item.branchId === option.branchId) !== -1) || ''}
            withoutSearchButton
            inputPlaceholder={t(`${translationPath}Select-multiple-branchName`)}
            isLoading={loadings}
            onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                            getAllBranches(value);
                        }, 700);
                    }}
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'rotationSchemeBranches',
                            value: newValue &&
                                newValue.map((x) => (
                                    { branchId: x.branchId }
                                ))
                        };
                        const localNewSelected = newValue &&
                            newValue.map((x) => ({
                                branchId: x.branchId,
                                branchName: x.branchName,
                        }))
                        onStateChanged(localNewValue);
                        setCurrentSelected(prevState=>({
                            ...prevState,
                            rotationSchemeBranches: localNewSelected,
                        }));

                        if (rotationEdit) onScemaChanged('edit', 'rotationSchemeBranches');
                    }}
          />
          <AutocompleteComponent
            idRef='teams-ToRef'
            labelValue='teams'
            selectedValues={currentSelected?.rotationSchemeTeams || []}
            multiple
            data={ResponseTeam.result || []}
            chipsLabel={(option) => option.teamsName || option.teamName || ''}
            isDisabled={(currentSelected?.rotationSchemeBranches
             && currentSelected.rotationSchemeBranches.length > 0)}
            displayLabel={(option) => t(`${(option.teamsName || option.teamName ) || ''}`)}
            getOptionSelected={(option) =>
                        currentSelected?.rotationSchemeTeams &&
                 currentSelected.rotationSchemeTeams.findIndex((item) => item && ((item.teamsId === option.teamsId) 
                 || (item.teamId === option.teamsId))) !== -1 || ''}
            withoutSearchButton
            inputPlaceholder={t(`${translationPath}Select-multiple-teams`)}
            isLoading={loadingsTeam}
            onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                            GetMyTeam(value);
                        }, 700);
                    }}
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'rotationSchemeTeams',
                            value: newValue &&
                                newValue.map((item) => ({
                                    teamId: item.teamsId  || item.teamId,
                                }))
                        };
                        const localNewSelected = newValue &&
                        newValue.map((item) => ({
                            teamId: item.teamsId || item.teamId,
                            teamName: item.teamsName || item.teamName,
                        }));

                        onStateChanged(localNewValue);
                        if (rotationEdit) onScemaChanged('edit', 'rotationSchemeTeams');
                        setCurrentSelected(prevState=>({
                            ...prevState,
                            rotationSchemeTeams: localNewSelected,
                        }));
                    }}
          />
          <div className='mt-1'>
            <Inputs
              idRef='slaRef'
              labelValue={t(`${translationPath}sla`)}
              value={state.sla || ''}
              isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                        if (isNaN(event.target.value))
                            return;
                        onStateChanged({ id: 'sla', value: event.target.value });
                    }}
            />
          </div>
        </div>
      </>
    );
};

SchemaSetting.propTypes =
{
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    state: PropTypes.PropTypes.instanceOf(Object),
    onStateChanged: PropTypes.func.isRequired,
    schema: PropTypes.instanceOf(Object).isRequired,
    isSubmitted: PropTypes.bool.isRequired,
};
