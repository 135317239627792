import React, { useContext, useState, useMemo, useEffect } from "react";
import moment from "moment";
import { Box } from "@material-ui/core";
import {
  CustomDateRangePicker,
  CustomIconButton,
  CustomInput,
  CustomSelect,
  ViewModeSwitcher,
  RolePinSideTab,
  VIEW_MODES,
} from "../../../../../Components";
import { ContactLayoutContext } from "../../../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import {
  useIsDesktop,
  useSelectedTheme,
  useTranslate,
} from "../../../../../Hooks";
import { LeadsClassTypesEnum } from "../../../../../Enums";

// Styles
import useStyles from "./styles";

// Icons
import { ColumnsThree, SettingsFour } from "../../../../../assets/icons";

function QuickFilterSection({
  viewMode,
  onChangeViewMode,
  setUpdateSelectedColumnItems,
  allFormFields,
  tableColumns,
}) {
  const { isLoading, setAdvancedSearchBody, advancedSearchBody } =
    useContext(ContactLayoutContext);

  const { translate } = useTranslate("ContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const [isPinTabOpen, setIsPinTabOpen] = useState(false);

  const handleClosePinSideTab = () => {
    setIsPinTabOpen(false);
  };

  const [selectedDateOption, setSelectedDateOption] = useState(1);

  const fromDateKey = useMemo(() => {
    return selectedDateOption === 1 ? "fromDate" : "updatedFromDate";
  }, [selectedDateOption]);

  const toDateKey = useMemo(() => {
    return selectedDateOption === 1 ? "toDate" : "updatedToDate";
  }, [selectedDateOption]);

  const [showQuickFiltersSection, setShowQuickFiltersSection] = useState(true);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const { isDesktop } = useIsDesktop();

  const handleSelectTypeChange = (newValue) => {
    setAdvancedSearchBody((prev) => {
      const { contactTypeStr, ...restCriteria } = prev.criteria;

      // Check if the new value is 0 and the previous value was also 0
      if (!newValue && !contactTypeStr) {
        return prev; // No state update needed
      }

      return {
        ...prev,
        criteria: !newValue
          ? restCriteria
          : {
              ...restCriteria,
              contactTypeStr: [{ searchType: 1, value: newValue }],
            },
      };
    });
  };

  const handleApplyDateFilter = (ranges) => {
    setAdvancedSearchBody((prev) => {
      const { [fromDateKey]: _, [toDateKey]: __, ...restBody } = prev;
      const isDateRangeEmpty = !ranges.startDate && !ranges.endDate;

      if (isDateRangeEmpty) {
        return {
          ...restBody,
        };
      }

      return {
        ...prev,
        [fromDateKey]: moment(ranges.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        [toDateKey]: moment(ranges.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    });
  };

  useEffect(() => {
    if (!dateFilter.startDate && !dateFilter.endDate) {
      return;
    }

    setAdvancedSearchBody((prev) => {
      const { fromDate, updatedFromDate, toDate, updatedToDate, ...restBody } =
        prev;
      const isDateRangeEmpty = !dateFilter.startDate && !dateFilter.endDate;

      if (isDateRangeEmpty) {
        return {
          prev,
        };
      }

      return {
        ...restBody,
        [fromDateKey]: moment(dateFilter.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        [toDateKey]: moment(dateFilter.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    });
  }, [selectedDateOption]);

  return (
    <>
      <Box className={styles.filtersContainer}>
        <CustomIconButton
          variant="outlined"
          size="md"
          boxShadow="none"
          color="secondary"
          onClick={() => setShowQuickFiltersSection((prev) => !prev)}
        >
          <SettingsFour
            width="20"
            height="20"
            fill={palette.button.secondary_fg}
          />
        </CustomIconButton>

        {viewMode === VIEW_MODES.TABLE && (
          <CustomIconButton
            variant="outlined"
            size="md"
            boxShadow="none"
            color="secondary"
            onClick={() => setIsPinTabOpen((prev) => !prev)}
          >
            <ColumnsThree
              width="20"
              height="20"
              fill={palette.button.secondary_fg}
            />
          </CustomIconButton>
        )}

        <ViewModeSwitcher
          viewMode={viewMode}
          onChangeViewMode={onChangeViewMode}
          isLoading={isLoading}
        />
      </Box>

      {showQuickFiltersSection && (
        <Box className={styles.quickFilters}>
          <Box className={styles.fieldWrapper}>
            <Box className={styles.selectFields}>
              <CustomSelect
                options={[
                  { id: "Individual", name: "Individual" },
                  { id: "Corporate", name: "Corporate" },
                ]}
                onValueChange={handleSelectTypeChange}
                emptyItem={{
                  id: 0,
                  name: "Select Type",
                  isDisabled: false,
                }}
                variant="default"
                disabled={isLoading}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                defaultValue={
                  advancedSearchBody.criteria?.contactTypeStr?.[0]?.value ?? ""
                }
                customSelectClasses={styles.customSelect}
              />

              <CustomSelect
                isMulti
                isClearable
                placeholder="Lead Class"
                options={Object.values(LeadsClassTypesEnum)}
                disabled={isLoading}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.key}
                onValueChange={(selectedValues) => {
                  setAdvancedSearchBody((prev) => ({
                    ...prev,
                    LeadClasses: selectedValues,
                  }));
                }}
                defaultValue={advancedSearchBody?.LeadClasses}
                customSelectClasses={styles.customSelect}
              />
            </Box>
            <Box>
              <CustomInput
                type="text"
                placeholder="Name, Mobile, Email"
                onChange={(e) => {
                  const value = e.target.value.trim();
                  setAdvancedSearchBody((prev) => {
                    const { All, ...restCriteria } = prev.criteria;
                    // Check if the new value is empty and the previous value was also empty
                    if (!value && !All) {
                      return prev; // No state update needed
                    }
                    return {
                      ...prev,
                      criteria: !value
                        ? restCriteria
                        : {
                            ...restCriteria,
                            All: [{ searchType: 2, value }],
                          },
                    };
                  });
                }}
                debounceTime={1000}
                disabled={isLoading}
                value={
                  advancedSearchBody.criteria?.All?.[0]?.value
                    ? advancedSearchBody.criteria?.All?.[0]?.value
                    : ""
                }
                inputContainerOverrideStyles={styles.customInputContainer}
              />
            </Box>
          </Box>
          <Box className={styles.fieldWrapper}>
            <CustomSelect
              style={{ width: "165px" }}
              options={[
                { key: 1, value: translate("created-date") },
                { key: 2, value: translate("update-on-date") },
              ]}
              onValueChange={(e) => setSelectedDateOption(e)}
              variant="default"
              disabled={isLoading}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.key}
              defaultValue={selectedDateOption} // created date by default
            />

            <CustomDateRangePicker
              onClearClicked={() => {
                setDateFilter(dateRangeDefault);

                setAdvancedSearchBody((prev) => {
                  const {
                    [fromDateKey]: _,
                    [toDateKey]: __,
                    ...restBody
                  } = prev;

                  return {
                    ...restBody,
                  };
                });
              }}
              ranges={[dateFilter]}
              onDateChanged={(selectedDate) => {
                setDateFilter((item) => ({
                  ...item,
                  startDate:
                    selectedDate.selection && selectedDate.selection.startDate,
                  endDate: !selectedDate.selection?.endDate
                    ? null
                    : new Date(
                        moment(
                          selectedDate.selection &&
                            selectedDate.selection.endDate
                        ).endOf("day")
                      ),
                  key: "selection",
                }));
              }}
              emptyLabel="All"
              displayFormat="MMM D, YYYY"
              onSave={handleApplyDateFilter}
            />
          </Box>
        </Box>
      )}

      <RolePinSideTab
        setUpdateSelectedColumnItems={setUpdateSelectedColumnItems}
        open={isPinTabOpen}
        onClose={handleClosePinSideTab}
        allFormFields={allFormFields}
        tableColumns={tableColumns}
      />
    </>
  );
}

export default QuickFilterSection;
