import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LandlordInfoStyle.scss';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GlobalHistory } from '../../../../../../../Helper';
import { GetSensitiveFieldsForUser } from '../../../../../../../Services';
import { AgentTypesEnum, FormsIdsEnum, LeadOwnerDataKeys } from '../../../../../../../Enums';
import { ActiveItemActions } from '../../../../../../../store/ActiveItem/ActiveItemActions';

export const LandlordInfo = ({ parentTranslationPath, leaseLeadOwnerData, unitDetails }) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const dispatch = useDispatch();
  const [sensitiveFieldsForUser, setSensitiveFieldsForUser] = useState(null);
  const [leadSensitiveFields, setLeadSensitiveFields] = useState(null);
  const [contactSensitiveFields, setContactSensitiveFields] = useState(null);
  const [dataWithSensitiveFields, setDataWithSensitiveFields] = useState(null);

  const getSensitiveFieldsForUser = useCallback(async (formType, formId, sensitiveFieldsObj) => {
    const response = await GetSensitiveFieldsForUser(formId , sensitiveFieldsObj  );

    if (!(response && response.status && response.status !== 200)) {
      if (formType === "Contact") setContactSensitiveFields(response);
      if (formType === "Lead") setLeadSensitiveFields(response);
    } else {
      if (formType === "Contact") setContactSensitiveFields(response);
      if (formType === "Lead") setLeadSensitiveFields(response);
    }
  }, []);

  const checkIsUnitOwnerSensitive = () => {
    return unitDetails.unit?.lease_lead_owner?.name === "********";
  }


  const checkIsFieldSensitive = (fieldKey) => {
    const isKeyFound = sensitiveFieldsForUser.find(item => fieldKey === item.key)
    const isFieldSensitive = isKeyFound ? true : false;
    return isFieldSensitive;
  }

  const HandleSensitiveFields = () => {

    if (checkIsUnitOwnerSensitive()) {
      const newLeadOwnerData = {};
      LeadOwnerDataKeys[leaseLeadOwnerData.contactsTypeId].forEach(item => {
        const isFieldSensitive = checkIsFieldSensitive(item.fieldkey)
        if (isFieldSensitive) {
          newLeadOwnerData[item.name] = '********';
        } else {
          newLeadOwnerData[item.name] = leaseLeadOwnerData[item.name];
        }
      });
      setDataWithSensitiveFields(newLeadOwnerData);
    } else setDataWithSensitiveFields(leaseLeadOwnerData);

  }


  const moveToContact = () => {
    if (leaseLeadOwnerData) {
      dispatch(ActiveItemActions.activeItemRequest({ name: leaseLeadOwnerData.contactName, id: leaseLeadOwnerData.contactId }));
      GlobalHistory.push(
        `/home/Contacts-CRM/contact-profile-edit?formType=${leaseLeadOwnerData.contactsTypeId}&id=${leaseLeadOwnerData.contactId}`
      );
    }
  };

  const moveToLead = () => {
    if (leaseLeadOwnerData)
      dispatch(ActiveItemActions.activeItemRequest({ id: leaseLeadOwnerData.leadId, name: leaseLeadOwnerData.contactName }));
    const leadClass = leaseLeadOwnerData && leaseLeadOwnerData.leadClass && (leaseLeadOwnerData.leadClass === 'Seller' || leaseLeadOwnerData.leadClass === 'Landlord') ? 1 : 2;
    GlobalHistory.push(
      `/home/lead-lease/lead-profile-edit?formType=${leadClass}&id=${leaseLeadOwnerData.leadId}&leadClass=${leaseLeadOwnerData.leadClass}`
    );
  };

  useEffect(() => {
    const leadFormType = "Lead";
    const isSeekerLead = leaseLeadOwnerData.leadClass === AgentTypesEnum.Tenant.value;
    const leadFormId = isSeekerLead ? FormsIdsEnum.leadsSeeker.id : FormsIdsEnum.leadsOwner.id;

    if (leadFormId) getSensitiveFieldsForUser(leadFormType, leadFormId);

    const contactFormType = "Contact";
    const contactFormId = leaseLeadOwnerData.contactsTypeId;
    if (contactFormId) getSensitiveFieldsForUser(contactFormType, contactFormId , leaseLeadOwnerData ? { key : 'contact' ,  id : (leaseLeadOwnerData &&  leaseLeadOwnerData.contactId  )} : null);
  }, [leaseLeadOwnerData]);


  useEffect(() => {
    if (contactSensitiveFields && leadSensitiveFields)
      setSensitiveFieldsForUser([...contactSensitiveFields, ...leadSensitiveFields]);
  }, [contactSensitiveFields, leadSensitiveFields]);

  useEffect(() => {
    if (leaseLeadOwnerData && sensitiveFieldsForUser && unitDetails) HandleSensitiveFields();
  }, [leaseLeadOwnerData, sensitiveFieldsForUser, unitDetails]);



  return (
    <>
      {sensitiveFieldsForUser && <div className='landlordInformation'>
        <div className='card'>
          <div>
            <span className='titleSection'>
              {t('landlordInformation')}
            </span>
          </div>
          <div className='landlordInfoContant'>
            <div className='sectionItem'>
              <span className='mdi mdi-account-tie  mr-1' />
              <span className='headtitle mr-1'>{`${t('lead-name')} : `}</span>
              {' '}
              {dataWithSensitiveFields && dataWithSensitiveFields.contactName ? (
                <a href='# ' onClick={() => moveToContact()}>
                  <span className='navbar-item' activeClassName='is-active'>
                    {(dataWithSensitiveFields.contactName)}
                  </span>
                </a>
              ) : 'N/A'}

            </div>
            <div className='sectionItem'>
              <span className='mdi mdi-clipboard-account mr-1' />
              <span className='headtitle mr-1'>{`${t('lead-ID')} : `}</span>
              {' '}
              {dataWithSensitiveFields && dataWithSensitiveFields.leadId ? (
                <a href='# ' onClick={() => moveToLead()}>
                  <span className='navbar-item' activeClassName='is-active'>
                    {dataWithSensitiveFields.leadId}
                  </span>
                </a>
              ) : 'N/A'}
            </div>

            <div className='sectionItem'>
              <span className='mdi mdi-phone  mr-1' />
              <span className='headtitle mr-1'>{`${t('lead-phone')} : `}</span>
              {' '}
              {(dataWithSensitiveFields && dataWithSensitiveFields.phone) || 'N/A'}

            </div>
            <div className='sectionItem'>
              <span className='mdi mdi-email-outline  mr-1'> </span>
              <span className='headtitle mr-1'>{`${t('lead-email')} : `}</span>
              {' '}
              {(dataWithSensitiveFields && dataWithSensitiveFields.email) || 'N/A'}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

LandlordInfo.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  leaseLeadOwnerData: PropTypes.instanceOf(Object).isRequired,
};
