import React from "react";
import PropTypes from "prop-types";
import { Box, InputLabel, IconButton } from "@material-ui/core";

import { CloseXIcon } from "../../../assets/icons";
import { useSelectedTheme, useIsDesktop, useTranslate } from "../../../Hooks";
import { CustomButton } from "../CustomButton";

import useStyles from "./styles";

const CustomSnackbar = ({
  isExpanded,
  setIsExpanded,
  primaryMessage,
  secondaryMessage,
}) => {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { isDesktop } = useIsDesktop();

  return (
    <Box
      className={!isExpanded ? styles.collapsed : styles.expandedSnackBar}
      style={{
        justifyContent: isDesktop
          ? isExpanded
            ? "space-between"
            : "flex-end"
          : "center",
      }}
    >
      <Box
        className={styles.alertContainer}
        style={{ justifyContent: isDesktop ? "unset" : "center" }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <img
          alt="alertIcon"
          width="38"
          height="38"
          src="/images/contacts/alertIcon.svg"
        />

        <InputLabel className={styles.text} style={{ cursor: "pointer" }}>
          {!isExpanded ? "Potential duplicate" : primaryMessage}
        </InputLabel>
        {isExpanded && (
          <InputLabel className={styles.secondaryText}>
            {secondaryMessage}
          </InputLabel>
        )}
      </Box>

      {isExpanded && (
        <Box className={styles.buttonsContainer}>
          <CustomButton
            boxShadow="none"
            size="lg"
            variant="text"
            color="tertiaryGray"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {translate("DISMISS")}
          </CustomButton>

          <CustomButton
            boxShadow="none"
            size="lg"
            variant="text"
            color="tertiary"
          >
            {translate("PROCEED")}
          </CustomButton>

          <IconButton
            aria-controls="close-icon"
            aria-haspopup="true"
            className={styles.closeButton}
            disableRipple
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <CloseXIcon
              width="20"
              height="20"
              fill={palette.foreground.disabled}
              className={styles.closeIcon}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

CustomSnackbar.propTypes = {
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.bool,
  primaryMessage: PropTypes.string.isRequired,
  secondaryMessage: PropTypes.string,
};

CustomSnackbar.defaultProps = {
  secondaryMessage: "",
  setIsExpanded: false,
  isExpanded: false,
};

export default CustomSnackbar;
