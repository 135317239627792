
import React  , {  useState , useEffect , useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from '../../../Hooks';
import { ActionsButtonsEnum , ActionsEnum  } from '../../../Enums' ; 
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { Spinner  , ActionsButtonsComponent , ViewTypes    } from '../../../Components' ; 
import { generateUniqueKey , GlobalHistory  } from '../../../Helper' ; 


 const parentTranslationPath = 'Developers';
 const translationPath = '';
  
  export const DevelopersView = () => { 

    const dispatch = useDispatch();
    const [activeFormType, setActiveFormType] = useState(1);
   

    const{ t } = useTranslation(parentTranslationPath);
    const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());
    useTitle(t(`${translationPath}developers`));

    const onActionsButtonClicked = useCallback(
      (activeAction) => {
         if (activeAction === ActionsButtonsEnum[1].id) {
          GlobalHistory.push(
            `/home/developers/add`
          );
      }
      },
      []
    );
    return (
      <div className='view-wrapper contacts-view'>
        {/* <Spinner isActive={isLoading} /> */}
        <div className='d-flex-column'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='section'>
                <ActionsButtonsComponent
                 key={actionButtonsKey}
                 onActionsButtonClicked={onActionsButtonClicked}              
                />
              </div>
              <div className='section autocomplete-section'>
               
                  <div className='d-flex-column px-2 w-100 p-relative'>
                    {/* <div className='mb-1'>
                      <AutocompleteComponent
                        data={
                          searchableFormFields &&
                          searchableFormFields.map((item) => ({
                            key: item.key,
                            title: item.title,
                          }))
                        }
                        wrapperClasses='autocomplete-with-btn'
                        selectedValues={searchData}
                        parentTranslationPath='ContactsView'
                        displayLabel={displayedLabel}
                        disabledOptions={disabledOptions}
                        onChange={filterOnChange}
                        searchClicked={searchClicked}
                        chipsLabel={chipsLabel}
                        getOptionSelected={(option) =>
                          searchData.findIndex(
                            (item) =>
                              item.key === option.key &&
                              item.value === searchInputValue
                          ) !== -1
                        }
                        tagValues={searchData}
                        inputValue={searchInputValue}
                        onInputChange={inputValueChanged}
                        inputLabel='filter'
                        inputPlaceholder='search-contacts'
                      />
                    </div> */}
                    <div className='d-flex-v-center-h-between pl-5-reversed'>
                      <div className='mr-1-reversed'>
                        {/* <SelectComponet
                          data={Object.values(FormsIdsEnum).filter(
                            (item) => item.page === 'contacts'
                          )}
                          emptyItem={{
                            value: 0,
                            text: 'select-type',
                            isDisabled: false,
                          }}
                          value={activeFormType}
                          valueInput='id'
                          textInput='name'
                          onSelectChanged={changeActiveFormType}
                          wrapperClasses='w-auto'
                          themeClass='theme-default'
                          idRef='Contact_Type'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        /> */}
                      </div>
                      <div className='mr-1-reversed'>
                        {/* <SelectComponet
                          data={['yes', 'no']}
                          emptyItem={{
                            value: 0,
                            text: 'opportunity-contact',
                            isDisabled: false,
                          }}
                          value={orderFilter.opportunityContact ?? 0}
                          onSelectChanged={changeContactOpportunity}
                          wrapperClasses='w-auto'
                          themeClass='theme-default'
                          idRef='Contact_Type'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        /> */}
                      </div>
  
                      <div className='mr-1-reversed'>
                        {/* <SelectComponet
                          getIsChecked={getIsChecked}
                          singleIndeterminate={
                            selectedSearchIn &&
                            selectedSearchIn.length > 0 &&
                            Object.values(LeadsClassTypesEnum).length > 0 &&
                            Object.values(LeadsClassTypesEnum).length >
                              selectedSearchIn.length
                          }
                          singleChecked={
                            selectedSearchIn &&
                            selectedSearchIn.length > 0 &&
                            Object.values(LeadsClassTypesEnum).length ===
                              selectedSearchIn.length - 1
                          }
                          renderValue={(value) =>
                            (selectedSearchIn.length > 0 && (
                              <span>
                                {value.map((option, mapIndex) => {
                                  let toReturn = '';
                                  const optionIndex = Object.values(
                                    LeadsClassTypesEnum
                                  ).findIndex(
                                    (element) => element.key === option
                                  );
                                  if (optionIndex !== -1) {
                                    toReturn +=
                                      Object.values(LeadsClassTypesEnum)[
                                        optionIndex
                                      ].value;
                                    if (mapIndex < value.length - 1)
                                      toReturn += ', ';
                                  }
                                  return toReturn;
                                })}
                              </span>
                            )) || (
                              <span>
                                {t(`${translationPath}select-Leads-Class`)}
                              </span>
                            )
                          }
                          data={Object.values(LeadsClassTypesEnum)}
                          value={LeadClassesFilter}
                          multiple
                          isWithCheckAll
                          valueInput='key'
                          keyLoopBy='key'
                          textInput='value'
                          onSelectChanged={searchInChangedHandler}
                          wrapperClasses='w-auto'
                          themeClass='theme-default'
                          idRef='headerSearchRef'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        /> */}
                      </div>
                      <div className='mr-1-reversed'>
                        {/* <SelectComponet
                          getIsChecked={getIsContactClassChecked}
                          singleIndeterminate={
                            contactClasses &&
                            contactClasses.length > 0 &&
                            Object.values(ContactClassTypesEnum).length > 0 &&
                            Object.values(ContactClassTypesEnum).length >
                              contactClasses.length
                          }
                          renderValue={(value) =>
                            (contactClasses.length > 0 && (
                              <span>
                                {value.map((option, mapIndex) => {
                                  let toReturn = '';
                                  const optionIndex = Object.values(
                                    ContactClassTypesEnum
                                  ).findIndex(
                                    (element) => element.value === option
                                  );
                                  if (optionIndex !== -1) {
                                    toReturn += Object.values(
                                      ContactClassTypesEnum
                                    )[optionIndex].value;
                                    if (mapIndex < value.length - 1)
                                      toReturn += ', ';
                                  }
                                  return toReturn;
                                })}
                              </span>
                            )) || (
                              <span>
                                {t(`${translationPath}select-contact-class`)}
                              </span>
                            )
                          }
                          singleChecked={
                            contactClasses &&
                            contactClasses.length > 0 &&
                            Object.values(ContactClassTypesEnum).length ===
                              contactClasses.length - 1
                          }
                          data={Object.values(ContactClassTypesEnum)}
                          value={contactClassesFilter}
                          isWithCheckAll
                          valueInput='value'
                          multiple={true}
                          keyLoopBy='value'
                          textInput='value'
                          onSelectChanged={changeContactClass}
                          wrapperClasses='w-auto'
                          themeClass='theme-default'
                          idRef='changeContactClassRef'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        /> */}
                      </div>
  
                      <div className='w-100 mt-2'>
                        {/* <Inputs
                          value={searchedItem}
                          onKeyUp={(e) => searchHandler(e.target.value)}
                          idRef='activitiesSearchRef'
                          labelClasses='mt-4'
                          onInputChanged={(e) => setSearchedItem(e.target.value)}
                          inputPlaceholder={t(
                            `${translationPath}search-Mobile-Email-ID`
                          )}
                        /> */}
                      </div>
                      <div className='w-100 customDatePicker'>
                        <div className='date-type-select'>
                          {/* <SelectComponet
                            data={[
                              { key: 1, value: `${translationPath}created-date` },
                              {
                                key: 2,
                                value: `${translationPath}update-on-date`,
                              },
                            ]}
                            defaultValue={{
                              key: 1,
                              value: `${translationPath}created-date`,
                            }}
                            value={dateFilter.selectedDateType || 1}
                            valueInput='key'
                            textInput='value'
                            isDisabled={isLoading}
                            onSelectChanged={(value) => {
                              setIsDatePickerChanged(true);
                              setDateFilter((f) => ({
                                ...f,
                                selectedDateType: value,
                              }));
                            }}
                            wrapperClasses='w-auto'
                            themeClass='theme-transparent'
                            idRef='Date_Select'
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            translationPathForData={translationPath}
                          /> */}
                        </div>
                        {/* <DateRangePickerComponent
                          onClearClicked={() => {
                            setDateFilter(dateRangeDefault);
                            setIsDatePickerChanged(true);
                          }}
                          onDialogClose={() => setIsDatePickerChanged(true)}
                          ranges={[dateFilter]}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onDateChanged={(selectedDate) => {
                            setDateFilter((item) => ({
                              ...item,
                              startDate: selectedDate?.selection?.startDate,
                              endDate: new Date(
                                moment(selectedDate?.selection?.endDate).endOf(
                                  'day'
                                )
                              ),
                              key: 'selection',
                            }));
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                <div className='view-search-wrapper'>
                    <ViewTypes
                      // onTypeChanged={onTypeChanged}
                      // initialActiveType={activeActionType}
                      className='mb-3'
                    />
                    <span className='advance-search'>
                      <ButtonBase
                        //onClick={onAdvanceSearchClick}
                        id='AdvanceSearch_bbt'
                      //  disabled={isAllFormFieldsLoading}
                        // className={`btns theme-solid ${
                        //   isAdvanceSearchActive &&
                        //   !isAllFormFieldsLoading &&
                        //   'is-active-filter'
                        // }`}
                      >
                        <span
                          // className={`${
                          //   advanceSearchBtn
                          //     ? 'mdi mdi-arrow-expand-left'
                          //     : 'mdi mdi-arrow-expand-right'
                          // } mr-1`}
                        />
                        {t(`${translationPath}advance-search`)}
                      </ButtonBase>
                    </span>
                    {/* <ButtonBase
                      onClick={onClearedAllFiltersClick}
                      id='onClearedAllFiltersref'
                      disabled={isAllFormFieldsLoading}
                      className='btns theme-solid bg-danger clear-all-btn'
                    >
                      <span className='mdi mdi-filter-remove m-1' />
                      {t(`${translationPath}clear-filters`)}
                    </ButtonBase> */}
                  
                </div>
              </div>
            </div>
              <div className='d-flex px-2'>
                <span className='mx-2 mt-1'>
                  {t(`${translationPath}contacts`)}
                </span>
                <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
                <span className='px-2 d-flex'>
                  <span className='texts-large mt-1'>
                    {t(`${translationPath}order-by`)}:
                  </span>
                  <div className='px-2'>
                    {/* <SelectComponet
                      idRef='filterByRef'
                      data={[
                        { id: 'createdOn', filterBy: 'created-on' },
                        { id: 'updateOn', filterBy: 'last-updated' },
                      ]}
                      value={selectedOrderBy.filterBy}
                      onSelectChanged={filterByChanged}
                      wrapperClasses='mb-3'
                      isRequired
                      valueInput='id'
                      textInput='filterBy'
                      emptyItem={{
                        value: null,
                        text: 'select-filter-by',
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    /> */}
                  </div>
                  <div className='px-2'>
                    {/* <SelectComponet
                      idRef='orderByRef'
                      data={[
                        { id: 1, orderBy: 'ascending' },
                        { id: 2, orderBy: 'descending' },
                      ]}
                      value={selectedOrderBy.orderBy}
                      onSelectChanged={orderByChanged}
                      wrapperClasses='mb-3'
                      isRequired
                      valueInput='id'
                      textInput='orderBy'
                      emptyItem={{
                        value: null,
                        text: 'select-sort-by',
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    /> */}
                  </div>
                  <div className='mt-1'>
                    <ButtonBase
                      className='btns theme-solid'
                      // onClick={orderBySubmitted}
                      // id='action_apply'
                      // disabled={
                      //   !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                      // }
                    >
                      <span>{t(`${translationPath}apply`)}</span>
                    </ButtonBase>
                    {/* {(activeActionType !== ViewTypesEnum.tableView.key &&
                      activeSelectedAction === 'merge' && (
                        <span className='fw-simi-bold'>
                          <span>
                            {t('Shared:Select')} : {checkedCards?.length || 0}
                          </span>
                        </span>
                      )) ||
                      ''} */}
                  </div>
                </span>
              </div>
          </div>
          {/* {activeActionType !== ViewTypesEnum.tableView.key && (
            
              <div className='body-section'>
                <ContactsCardsComponent
                  data={detailsContactsList}
                  isExpanded={isExpanded}
                  onCardClicked={onCardClick}
                  onFooterActionsClicked={detailedCardSideActionClicked}
                  onActionClicked={detailedCardActionClicked}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  withCheckbox={[
                    'merge',
                    ActionsButtonsEnum[4].id,
                    ActionsButtonsEnum[29].id,
                  ].includes(activeSelectedAction)}
                  onCardCheckboxClick={cardCheckboxClicked}
                  activeCard={activeCard}
                  selectedCards={checkedCards}
                  isCheckBoxDisabled={
                    activeSelectedAction === 'merge'
                      ? checkedCards.length >= 10
                      : false
                  }
                  displyOpenFileButton={displayOpenFileButton}
                  checkIfSensitiveField={checkIfSensitiveField}
                  contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}
                  activeAction={activeSelectedAction}
                />
              </div>
          )} */}
          {/* {activeActionType === ViewTypesEnum.tableView.key && (
              <ContactsTableComponent
                filter={filter}
                pathName={pathName}
                reloadData={reloadData}
                onRowClick={onRowClicked}
                checkedCards={checkedCards}
                setCheckedCards={setCheckedCards}
                checkedCardsIds={checkedCardsIds}
                onActionClicked={detailedCardActionClicked}
                contactTableFilter={contactTableFilter}
                onFilterValuesChanged={onFilterValuesChanged}
                onPageSizeChanged={onPageSizeChanged}
                setCheckedCardsIds={setCheckedCardsIds}
                onPageIndexChanged={onPageIndexChanged}
                detailsContactsList={detailsContactsList}
                activeSelectedAction={activeSelectedAction}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setOrderBy={setOrderBy}
                isWithCheckboxColumn={[
                  'merge',
                  ActionsButtonsEnum[4].id,
                  ActionsButtonsEnum[29].id,
                ].includes(activeSelectedAction)}
                isClearFiltersClicked={isClearFiltersClicked}
                setIsClearFiltersClicked={setIsClearFiltersClicked}
                displyOpenFileButton={displayOpenFileButton}
                localStorageKey='ContactFilter'
                checkIfSensitiveField={checkIfSensitiveField}
                contactPreferenceFieldThanFour={contactPreferenceFieldThanFour}
              />
          )} */}
        </div>
        
      </div>
    );
  } ; 
  