import { config } from "../config/config";
import { showError, HttpServices } from "../Helper";

const GetMainEntitiesComments = async (entityType, entityId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/MainEntitiesComments/GetMainEntitiesComments/${entityType}/${entityId}`
    );
    return result;
  } catch (error) {
    showError("Failed to fetch main entities comments");
    return undefined;
  }
};

const CreateMainEntitiesComment = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/MainEntitiesComments/CreateMainEntitiesComment`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export { 
  GetMainEntitiesComments,
  CreateMainEntitiesComment,
};
