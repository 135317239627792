import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { GlobalTranslate, showError, showSuccess } from '../../../../Helper';
import { CloneLead, OrganizationUserSearch, lookupItemsGetId } from '../../../../Services';
import { AutocompleteComponent, DialogComponent } from '../../../../Components';
import { AgentRoleEnum } from '../../../../Enums';

const parentTranslationPath = '';
const translationPath = '';
export const CloneLeadView = ({
  relodedata, setIsLoading, isOpen, onClose, ActiveIteam
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [SelectedValue, setSelectedValue] = useState([]);
  const searchTimer = useRef(null);
  const [data, setData] = useState({
    campaignLanguages: [],
    mediaTypes: [],
    mediaDetails: [],
    users: [],
    referredBy: [],
    referredTo: [],
    methodOfContact: [],
    campaignTypes: [],
  });
  const [Dto, setDto] = useState({
    leadId: (ActiveIteam && ActiveIteam.id) || null,
    mediaDetailsId: '',
    methodOfContactId: '',
    referredBy: '',
    referredTo: ''
  });
  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, mediaDetails: res }));
    else
      setData((item) => ({ ...item, mediaDetails: [] }));
  }, []);

  const getAllMethodOfContact = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: 35 });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, methodOfContact: res }));
    else setData((item) => ({ ...item, methodOfContact: [] }));
  };

  const getAllReferred = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
      userTypeId: ActiveIteam && ActiveIteam.leadClass === 'Buyer' && (AgentRoleEnum.SaleAgent.value ||
        ActiveIteam && ActiveIteam.leadClass === 'Tenant' && (AgentRoleEnum.LeaseAgent.value)
      ) || null
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredTo: (res && res.result) }));
    else
      setData((item) => ({ ...item, referredTo: [] }));
  }, []);

  const getAllReferrby = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2
    });

    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredBy: (res && res.result) }));
    else
      setData((item) => ({ ...item, referredBy: [] }));
  }, []);

  const cloneHandler = async () => {
    const result = await CloneLead(Dto);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}leads-cloned-successfully`)); relodedata(); onClose();
    } else { showError(t(`${translationPath}leads-clone-failed`)); setIsLoading(false); }
  };
  useEffect(() => { getAllMediaDetails(); getAllMethodOfContact(); getAllReferred(); getAllReferrby(); }, []);

  function findObjectInArraySales(array) {
    return array.find((item) => (
      item.lookupItemName === 'Existing Client - Sales' ||
      item.lookupItemId === 57269
    ));
  }

  function findObjectInArrayLeasing(array) {
    return array.find((item) => (
      item.lookupItemName === 'Existing Client - Leasing' ||
      item.lookupItemId === 57270
    ));
  }
  useEffect(() => {
    if (data && data.mediaDetails && data.mediaDetails.length > 0) {
      if (ActiveIteam && ActiveIteam && ActiveIteam.leadClass === 'Buyer' || ActiveIteam && ActiveIteam && ActiveIteam.leadClass === 'Saller') {
        const result = findObjectInArraySales(data.mediaDetails);
        setSelectedValue(result);
        setDto((item) => ({ ...item, mediaDetailsId: (result && +result.lookupItemId) || '' }));
      } else if (ActiveIteam && ActiveIteam && ActiveIteam.leadClass === 'Tenant' || ActiveIteam && ActiveIteam && ActiveIteam.leadClass === 'Landlord') {
        const result = findObjectInArrayLeasing(data.mediaDetails);
        setSelectedValue(result);
        setDto((item) => ({ ...item, mediaDetailsId: (result && +result.lookupItemId) || '' }));
      }
    }
  }, [data]);

  return (
    <>
      <DialogComponent
        titleText={GlobalTranslate.t('Shared:clone-leads')}
        saveText={GlobalTranslate.t('Shared:confirm')}
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <span>
              {' '}
              {GlobalTranslate.t('Shared:Are-you-sure-to-clone-this-leads')}
              {' '}
              :
              {' '}
              <span className='fw-bold'>{ActiveIteam && ActiveIteam.contact_name && ActiveIteam.contact_name.name || ''}</span>
            </span>
            <div className='w-100 mr-1-reversed mt-3'>
              <AutocompleteComponent
                idRef='mediaDetailseRef'
                labelValue={GlobalTranslate.t('Shared:mediaDetails')}
                multiple={false}
                selectedValues={SelectedValue}
                getOptionSelected={(option) => option.lookupItemId === SelectedValue.lookupItemId}
                labelClasses='Requierd-Color'
                data={data.mediaDetails || []}
                displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
                withoutSearchButton
                inputPlaceholder={GlobalTranslate.t('Shared:SelectmediaDetails')}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  setSelectedValue(newValue);
                  setDto((item) => ({ ...item, mediaDetailsId: (newValue && +newValue.lookupItemId) || '' }));
                }}
              />
              {' '}

            </div>
            <div className='w-100 mr-1-reversed'>
              <AutocompleteComponent
                idRef='methodOfContactRef'
                labelValue={GlobalTranslate.t('Shared:method-Of-Contact')}
                inputPlaceholder={GlobalTranslate.t('Shared:Selectmethod-Of-Contact')}
                multiple={false}
                labelClasses='Requierd-Color'
                data={data.methodOfContact}
                displayLabel={(option) => option.lookupItemName}
                renderOption={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  setDto((item) => ({ ...item, methodOfContactId: (newValue && +newValue.lookupItemId) || '' }));
                }}
              />
            </div>
            <div className='w-100 mr-1-reversed'>
              <AutocompleteComponent
                idRef='ReferredByRef'
                labelValue={GlobalTranslate.t('Shared:ReferredBy')}
                // isLoading={isLoading.referred}
                inputPlaceholder={GlobalTranslate.t('Shared:SelectReferredBy')}
                labelClasses='Requierd-Color'
                data={(data.referredBy && data.referredBy) || []}
                onInputKeyUp={(e) => {
                  const newValue = e.target.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllReferrby(newValue || '');
                  }, 700);
                }}
                multiple={false}
                displayLabel={(option) =>
                  (option && option.fullName) || ''}
                chipsLabel={(option) => (option && option.fullName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  setDto((item) => ({ ...item, referredBy: newValue && newValue.id || '' }));
                }}
              />

            </div>
            <div className='w-100 mr-1-reversed'>
              <AutocompleteComponent
                idRef='ReferredToRef'
                labelValue={GlobalTranslate.t('Shared:ReferredTo')}
                inputPlaceholder={GlobalTranslate.t('Shared:ReferredTo')}
                data={(data.referredTo && data.referredTo) || []}
                onInputKeyUp={(e) => {
                  const newValue = e.target.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllReferred(newValue || '');
                  }, 700);
                }}
                multiple={false}
                displayLabel={(option) => (option && option.fullName) || ''}
                chipsLabel={(option) => (option && option.fullName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  setDto((item) => ({ ...item, referredTo: newValue && newValue.id || '' }));
                }}
              />
            </div>
          </div>
        )}
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        isOpen={isOpen}
        saveIsDisabled={!(Dto.methodOfContactId !== '' && Dto.referredBy !== '' && Dto.mediaDetailsId !== '')}
        onSaveClicked={() => cloneHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath.confirm}
      />
    </>
  );
};
