import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const parentTranslationPath = 'ContactOpportunity';

const headerData = [
  [
    {
      label: 'contact-name',
      input: 'name',
      component: (row) => (
        <Link
          target='_blank'
          to={`/home/Contacts-CRM/contact-profile-edit?formType=${
            row.contactsType === 'Individuals' ? 1 : 2
          }&id=${row.contactId}`}
        >
          {row.name}
        </Link>
      ),
    },
    { label: 'nationality', input: 'nationality' },
    {
      label: 'status',
      input: 'status',
      component: (item) =>
        i18next.t(item.status, { ns: parentTranslationPath }),
    },
    { label: 'pulled-by', input: 'createdBy' },
    {
      label: 'pulling-date',
      input: 'createdOn',
      isDate: true,
    },
    { label: 'documents-count', input: 'contactDocumentsCount' },
    {
      label: 'is-merged',
      input: 'isMarged',
      component: (item) =>
        i18next.t(item.isMarged ? 'yes' : 'no', { ns: parentTranslationPath }),
    },
  ],
  [
    {
      label: 'company-name',
      input: 'name',
      component: (row) => (
        <Link
          target='_blank'
          to={`/home/Contacts-CRM/contact-profile-edit?formType=${
            row.contactsType === 'Individuals' ? 1 : 2
          }&id=${row.contactId}`}
        >
          {row.name}
        </Link>
      ),
    },
    { label: 'company-nationality', input: 'nationality' },
    {
      label: 'status',
      input: 'status',
      component: (item) =>
        i18next.t(item.status, { ns: parentTranslationPath }),
    },
    { label: 'pulled-by', input: 'createdBy' },
    { label: 'pulling-date', input: 'createdOn', isDate: true },
    { label: 'documents-count', input: 'contactDocumentsCount' },
    {
      label: 'is-merged',
      input: 'isMarged',
      component: (item) =>
        i18next.t(item.isMarged ? 'yes' : 'no', { ns: parentTranslationPath }),
    },
  ],
];

export default headerData;
