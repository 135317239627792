import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  const { border, background } = theme.palette;

  return {
    root: {
      margin: "0",
      padding: "0",
    },
    icon: {
      borderRadius: 6,
      border: `1px solid ${border.border_disabled}`,

      "input:hover ~ &": {
        boxShadow: "0px 0px 0px 4px rgba(152, 162, 179, 0.14)",
      },
      "input:disabled ~ &": {
        background: background.disabled_subtle,
      },
    },
    checkedIcon: ({ isAllIconSelected }) => ({
      border: "none",
      background: background.brand_solid,

      "input:hover ~ &": {
        boxShadow: "0px 0px 0px 4px rgba(140, 115, 75, 0.24)",
      },

      "&:before": {
        display: "block",

        backgroundPosition: "center",
        background: "no-repeat",
        backgroundImage: `url(${
          isAllIconSelected
            ? "/images/contacts/minus.svg"
            : "/images/contacts/check-new.svg"
        })`,
        content: '""',
      },
    }),
    medium: {
      height: 20,
      width: 20,

      "&:before": {
        height: 20,
        width: 20,
      },
    },
    small: {
      height: 16,
      width: 16,

      "&:before": {
        height: 16,
        width: 16,
      },
    },
  };
});
