import React, { useState } from 'react';
import { Inputs } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';
import './UnitsBulkStatusUpdateDialog.scss';
import { CopyToClipboardComponents } from '../../../../../../ReusableComponents/UtilityComponents';
import Joi from 'joi';
import { getErrorByName } from '../../../../../../Helper';

function UnitBulkPublishDialog({
  checkedCards,
  unitData,
  setUnitData,
  isSubmitted,
  isLease,
  translationPath,
  parentTranslationPath,
  schema2,
  schemaErrors,
}) {
  const { t } = useTranslation(parentTranslationPath);

  const handleInputChange = (event, unitId) => {
    const { value } = event.target;
    setUnitData((prevUnitData) => {
      return prevUnitData.map((unit) => {
        if (unit.unitId === unitId && isLease === false) {
          return {
            ...unit,
            sellingPrice: +value,
            rentPerYearPrice: 0,
          };
        } else if (unit.unitId === unitId && isLease === true) {
          return {
            ...unit,
            sellingPrice: 0,
            rentPerYearPrice: +value,
          };
        }
        return unit;
      });
    });
  };

  const CheckPropertyName = (item) => {
    const ProbInfo = {
      refNo: checkedCards.find((card) => card.id === item.unitId)?.refNo,
      propertyName: checkedCards.find((card) => card.id === item.unitId)
        ?.propertyName,
    };
    return (
      <div className='flex-column'>
        <p>
          <b> {ProbInfo.propertyName} </b>
        </p>

        <div className='w-50'>
          <CopyToClipboardComponents
            data={ProbInfo.refNo}
            childrenData={ProbInfo.refNo}
            CustomizationClassName='block-container'
          />
        </div>
      </div>
    );
  };

  const getError = (index) => schemaErrors.find((item) => item.index === index);

  return (
    <>
      <div className='publish-dialog-data-and-inputs-container'>
        {unitData.map((item, index) => (
          <>
            <div className='d-flex'>
              <div className='w-25'>
                <CheckPropertyName {...item} />
              </div>

              <div className='d-flex-v-center'>
                <Inputs
                  key={index}
                  wrapperClasses='w-33'
                  idRef={`newPriceRef_${index}`}
                  labelValue={t(`${translationPath}enter-new-price`)}
                  labelClasses='Requierd-Color'
                  value={isLease ? item.rentPerYearPrice : item.sellingPrice}
                  onInputChanged={(event) =>
                    handleInputChange(event, item.unitId)
                  }
                  type={'number'}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  isSubmitted={isSubmitted}
                  helperText={getError(index)?.error}
                  error={!!getError(index)}
                />
                {isLease === true && (
                  <>
                    <h3> /{t('year')}</h3>
                  </>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
}

export default UnitBulkPublishDialog;
