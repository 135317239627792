import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../Components";
import { UnitsFilterStatusEnum } from "../../../Enums";
import {
  getProperties,
  GetLookupItemsByLookupTypeName,
  GetUnitsRefNumbers,
} from "../../../Services";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds.jsx";
import { MoreFiltersPrimaryUnitsDialog } from "./PrimaryUnitsComponents/MoreFiltersPrimaryUnitsDialog.jsx";
import { CustomButton } from "../ResaleUnitsView/ResaleUnitsFilters/utilities.js";
import { BedsAndBathFilter } from "../ResaleUnitsView/ResaleUnitsFilters/BedsAndBathFilter.jsx";

export const PrimaryUnitsFilterComponent = ({
  getUnitsData,
  isClearFiltersClicked,
  setFilterCriteria,
  filterCriteria,
  setDateFilter,
  dateFilter,
  setData,
  data,
  setSelected,
  selected,
  dateRangeDefault,
  filterCriteriaChangeHandler,
  restBedAndBath,
  filterBadsAndBaths,
  RangeSliderFilter,
  detailsUnitsList,
}) => {
  const parentTranslationPath = "UnitsView";
  const translationPath = "";

  const { t } = useTranslation(parentTranslationPath);
  const [SearchedItemUnitReferenceNumber, setSearchedItemUnitReferenceNumber] =
    useState("");
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);

  const searchTimer = useRef(null);

  const getPropertiesOptions = async (searchValue) => {
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || "",
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "properties", value: res?.result || [] });
    else setData({ id: "properties", value: [] });
  };

  const getUnitTypeLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "UnitType",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "unitTypes", value: res.result || [] });
    } else setData({ id: "unitTypes", value: [] });
  };

  const getUnitStatusOptions = () =>
    Object.values(UnitsFilterStatusEnum.sale).filter(
      (item) => item.value === "Draft" || item.value === "Available"
    );

  const GetAllUnitsRef = useCallback(async (search) => {
    setSearchedItemUnitReferenceNumber(true);
    const res = await GetUnitsRefNumbers({
      unitRefNo: search,
      pageIndex: 1,
      pageSize: 10,
      operationType: StaticLookupsIds.Sales,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "unitRef", value: res.result || [] });
    else setData({ id: "unitRef", value: [] });
    setSearchedItemUnitReferenceNumber(false);
  }, []);

  const handleClick = () => {
    setOpenFiltersDialog(true);
  };

  useEffect(() => {
    if (isClearFiltersClicked) {
      setFilterCriteria({});
      setSelected({ id: "edit", value: {} });
      setDateFilter(dateRangeDefault);
    }
  }, [isClearFiltersClicked]);

  useEffect(() => {
    getUnitsData(filterCriteria, dateFilter);
  }, [filterCriteria, dateFilter]);

  return (
    <div className="d-flex-column-center mb-3">
      <div className="d-flex-center  w-100">
        <AutocompleteComponent
          idRef="unitStatusRef"
          wrapperClasses="w-min-unset m-2 mr-1"
          inputPlaceholder={t(`${translationPath}Unit_Status`)}
          labelValue={t(`${translationPath}Unit_Status`)}
          selectedValues={selected.unitStatus || null}
          data={getUnitStatusOptions()}
          displayLabel={(option) => option?.value || ""}
          multiple={false}
          withoutSearchButton
          onChange={(_, newValue) => {
            setSelected({ id: "unitStatus", value: newValue });

            const statusKey = "StringStatus";
            const statusValue = newValue?.value || null;
            const searchType = 1;
            filterCriteriaChangeHandler(statusKey, statusValue, searchType);
          }}
        />
        <AutocompleteComponent
          idRef="propertiesRef"
          inputPlaceholder={t(`${translationPath}property`)}
          labelValue={t(`${translationPath}property`)}
          wrapperClasses="w-min-unset m-2"
          selectedValues={selected.property || null}
          multiple={false}
          data={data.properties || []}
          displayLabel={(option) =>
            option?.property?.property_name || option?.name || ""
          }
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            setSelected({ id: "property", value: newValue });

            const propertyKey = "property_name";
            const propertyValue = newValue?.property?.property_name || null;
            const searchType = 1;

            filterCriteriaChangeHandler(propertyKey, propertyValue, searchType);
          }}
          onOpen={() => {
            if (data.properties && data.properties.length == 0)
              getPropertiesOptions();
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getPropertiesOptions(value);
            }, 600);
          }}
        />

        <AutocompleteComponent
          idRef="unitTypeRef"
          wrapperClasses="w-min-unset m-2 mr-1"
          inputPlaceholder={t(`${translationPath}unit-Type`)}
          labelValue={t(`${translationPath}unit-Type`)}
          selectedValues={selected.unitType || null}
          data={data.unitTypes || []}
          displayLabel={(option) => option.lookupItemName || ""}
          multiple={false}
          withoutSearchButton
          onChange={(_, newValue) => {
            setSelected({ id: "unitType", value: newValue });
            const unitTypeKey = "unit_type";
            const unitTypeValue = newValue?.lookupItemName || null;
            const searchType = 1;

            filterCriteriaChangeHandler(unitTypeKey, unitTypeValue, searchType);
          }}
          onOpen={() => {
            if (data.unitTypes && data.unitTypes.length == 0)
              getUnitTypeLookups();
          }}
        />

        <AutocompleteComponent
          inputPlaceholder={t(`${translationPath}Unit-Reference-Number`)}
          labelValue={t(`${translationPath}Unit-Reference-Number`)}
          wrapperClasses="w-min-unset m-2 mr-1"
          data={data.unitRef}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          withoutSearchButton
          isLoading={SearchedItemUnitReferenceNumber}
          displayLabel={(option) => option?.unitRefNo || ""}
          onInputKeyUp={(event) => {
            const { value } = event.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              GetAllUnitsRef(value);
            }, 700);
          }}
          chipsLabel={(option) => option?.unitRefNo || ""}
          multiple={false}
          onChange={(_, newValue) => {
            if (newValue) {
              const propertyPlanValue = (newValue && newValue.unitRefNo) || "";
              const propertyPlanKey = "unit_ref_no";
              const searchType = 2;
              filterCriteriaChangeHandler(
                propertyPlanKey,
                propertyPlanValue,
                searchType
              );
            } else {
              setFilterCriteria({});
              setSelected({ id: "edit", value: {} });
              setDateFilter(dateRangeDefault);
              getUnitsData();
            }
          }}
        />
      </div>
      <div className="px-2 d-flex-center fas-start gap-1 mt-2">
        <BedsAndBathFilter
          restBedAndBath={restBedAndBath}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          selected={selected}
          setSelected={setSelected}
          filterBadsAndBaths={filterBadsAndBaths}
        />
        <CustomButton className="custom-filter-button" onClick={handleClick}>
          <span className="mdi mdi-filter-outline" />
          <span className="more-filters">
            {t(`${translationPath}more-filters`)}
          </span>
        </CustomButton>
        {openFiltersDialog && (
          <MoreFiltersPrimaryUnitsDialog
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            selected={selected}
            setSelected={setSelected}
            data={data}
            setData={setData}
            filterCriteriaChangeHandler={filterCriteriaChangeHandler}
            RangeSliderFilter={RangeSliderFilter}
            isOpen={openFiltersDialog}
            detailsUnitsList={detailsUnitsList}
            isClose={() => setOpenFiltersDialog(false)}
          />
        )}
      </div>
    </div>
  );
};
