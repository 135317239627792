import React from "react";
import clsx from "clsx";
import { useCopyToClipboard, useSelectedTheme } from "../../../Hooks";
import PropTypes from "prop-types";

// Icons
import { CopyIcon } from "../../../assets/icons";

// styles
import useStyles from "./styles";

function CustomCopyToClipboard(props) {
  const {
    childrenData,
    data,
    copyDoneShowTime,
    children,
    customContainerClasses,
  } = props;

  const { copied, copyToClipboardHandler } =
    useCopyToClipboard(copyDoneShowTime);

  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const handleCopy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    copyToClipboardHandler(data);
  };

  return (
    <div
      className={clsx(styles.copyToClipBoardContainer, {
        [customContainerClasses]: !!customContainerClasses,
      })}
      onClick={handleCopy}
    >
      {!!childrenData && <span>{childrenData}</span>}
      {children || (
        <CopyIcon
          width="20"
          height="20"
          fill={palette.button.tertiary_fg}
          style={{ marginInlineStart: "8px" }}
        />
      )}

      <div
        className={clsx(styles.tooltip, { [styles.tooltipVisible]: copied })}
      >
        ID Copied!
      </div>
    </div>
  );
}

CustomCopyToClipboard.propTypes = {
  copyDoneShowTime: PropTypes.number,
  childrenData: PropTypes.string,
  data: PropTypes.string,
  children: PropTypes.node,
};

CustomCopyToClipboard.defaultProps = {
  copyDoneShowTime: 2000,
  childrenData: undefined,
  data: undefined,
  children: null,
};

export default CustomCopyToClipboard;
