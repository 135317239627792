import React from "react";
import { DialogComponent } from "../../../../Components";
import { useSuccessDialog } from "../../../../SharedComponents";
import { ReactComponent as SuccessTickIcon } from "../../../../assets/images/icons/success-tick.svg";
import { Typography, Card, CardContent, Box, Button } from "@material-ui/core";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents";
import FormattedMessage from "../../../../SharedComponents/FormattedComponent/FormattedMessage";
import { useHistory } from 'react-router-dom';

export const PARENT_TRANSLATION_PATH = "GlobalSuccessDialog";

export function GlobalSuccessDialog() {
  const { isOpen, message, hideDialog, entity } = useSuccessDialog();
  const history = useHistory();
  
  return (
    <DialogComponent
      isOpen={isOpen}
      onCloseClicked={hideDialog}
      maxWidth="xs"
      closeClasses=""
      headingIcon={<SuccessTickIcon />}
      dialogContent={
        <>
          <Typography
            variant="h1"
            style={{
              fontSize: 18,
              fontWeight: 600,
              marginTop: 8,
              marginBottom: 8,
            }}
          >
            {message}
          </Typography>
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">{entity?.name}</Typography>
                <CopyToClipboardComponents
                  data={entity?.id}
                  childrenData={entity?.customId ? entity?.customId : `#${entity?.id}`}
                />
              </Box>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            style={{
              marginTop: 32,
              marginBottom: 24,
              backgroundColor: "#7F6944",
              color: "white",
              textTransform: "none",
            }}
            onClick={hideDialog}
          >
            <FormattedMessage id="confirm" path={PARENT_TRANSLATION_PATH} />
          </Button>
          { entity.isSale &&
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{
                marginBottom: 24,
                backgroundColor: "#7F6944",
                color: "white",
                textTransform: "none",
              }}
              onClick={() => {
                history.push(`/home/units-sales/unit-transaction-parameters?unitRefNo=${entity?.customId}`);
                hideDialog();
              }}
            >
              <FormattedMessage id="quick-transaction" path={PARENT_TRANSLATION_PATH} />
            </Button>
          }
        </>
      }
    />
  );
}
