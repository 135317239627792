import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    loaderFlex: { width: "100%" },
    tableFlexContainerLoader: {
      width: "100%",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.secondary}`,
      margin: 0,
      display: "grid",
      gridTemplateColumns: "repeat(1, minmax(0, auto))",
      gap: "16px",
      textAlign: "center",
      lineHeight: "unset !important",
      padding: "2px",
    },
    tableViewContainer: {
      width: "100%",

      "& thead": {
        width: "100%",
        height: "39px",
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.background.secondary,
      },

      "& tbody": {
        backgroundColor: theme.palette.background.paper,
      },

      "& tbody tr:not(:last-child)": {
        padding: "0 24px",
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
      },
    },
    displayFlex: {
      display: "flex",
    },
    skeletonCell: {
      border: "0.25vw",
      opacity: 0.5,
      margin: "1.1vw 1.8vw",
    },
    skeletonHeader: {
      padding: "0 24px",
      border: "0.25vw",
      opacity: 0.5,
      margin: "1.1vw 0",
    },
  };
});
