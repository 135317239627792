import React, { useState } from 'react';
import { DialogComponent, Spinner } from '../../../../../../Components';
import { DialogContentText } from '@material-ui/core';
import { SendLeadsToLeadPool } from '../../../../../../Services';
import { showError ,showSuccess } from '../../../../../../Helper';
import { useTranslation } from 'react-i18next';

export const SendToLeadsPool = ({
  isOpen,
  onClose,
  parentTranslationPath,
  selectedLeads,
  reloadData,
}) => {
  const [isLoading,setIsLoading]=useState(false)
  const { t } = useTranslation('LeadsView');
  const translationPath = 'utilities.sendToLeadsPool.';
  const onSave = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const res = await SendLeadsToLeadPool(selectedLeads);
      if (!(res.status && res?.status !== 200)) {
        onClose(); 
        showSuccess(t(`${translationPath}Sent-Leads-successfully`));
        reloadData();
      }
    } catch (error) {
         showError(t(`${translationPath}something-went-wrong`));
    }finally{
      setIsLoading(false)
    }
  };
  return (
    <DialogComponent
      isOpen={isOpen}
      titleText={'Sent-to-LeadPool'}
      dialogContent={
        <>
        <Spinner isAbsolute isActive={isLoading}/>
        <DialogContentText>
          {t(`${translationPath}confirm-message`)}
        </DialogContentText>
        </>
      }
      maxWidth={'sm'}
      cancelText='cancel'
      onCancelClicked={onClose}
      onSaveClicked={onSave}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      saveText={t(`${translationPath}Confirm`)}
    />
  );
};