import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    ContactCardWrapper: {
      alignItems: "center",
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "12px",
      color: theme.palette.text.primary,
      display: "flex",
      flex: "1 0 0",
      flexDirection: "column",
      gap: "16px",
      maxWidth: "339px",
      minWidth: "339px",
      padding: "20px 16px",
      width: "339px",
      cursor: "pointer",
    },
    details: {
      alignItems: "center",
      color: theme.palette.text.tertiary,
      display: "flex",
      gap: "10px",
      justifyContent: "center",

      "& label": {
        fontWeight: 400,
        fontSize: "16px",
      },

      "& span": {
        fontWeight: 700,
        fontSize: "14px",
      },
    },
    detailsWrapper: {
      direction: "ltr",
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    avatarWrapper: {
      alignItems: "center",
      backgroundColor: "#FFF",
      borderRadius: "50%",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
      display: "flex",
      height: "80px",
      justifyContent: "center",
      marginLeft: "33.33%",
      position: "relative",
      width: "80px",
    },
    defaultAvatar: {
      backgroundColor: "#F2F4F7", //avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "72px",
      width: "72px",

      "& img": {
        height: "36px",
        width: "36px",
      },
    },
    avatar: {
      backgroundColor: "#F2F4F7", //avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "72px",
      width: "72px",
    },
    flex: {
      display: "flex",
      width: "100%",
    },
    floatingBox: {
      background: "var(--colors-gray-dark-mode-700, #333741)",
      borderRadius: "var(--radius-full, 9999px)",
      filter: "blur(2px)",
      height: "25px",
      marginLeft: "-9px",
      marginTop: "53px",
      opacity: 0.17,
      position: "absolute",
      transform: "rotate(-2.174deg)",
      width: "118px",
    },
    userTextWrapper: {
      alignSelf: "self-end",
      display: "flex",
      position: "absolute",
    },
    userText: {
      alignItems: "flex-end",
      background: theme.palette.utility.brand_500,
      borderRadius: "9999px",
      color: "var(--white, #FFF)",
      display: "flex",
      fontSize: "14px",
      fontWeight: 500,
      marginLeft: "-6px",
      padding: "5px 40px 5px 12px",
      marginBottom: "2px",
    },
    idSection: {
      alignItems: "center",
      color: theme.palette.button.tertiary_fg,
      display: "flex",
      gap: "8px",
      justifyContent: "center",

      "& label": {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    cardHeader: {
      direction: "ltr",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    badgeSection: {
      display: "flex",
      gap: "6px",
    },
    userInfo: {
      direction: "ltr",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      fontSize: "14px",
      gap: "4px",

      "& label": {
        fontWeight: 400,
      },

      "& span": {
        fontWeight: 500,
      },
    },
    divider: {
      backgroundColor: theme.palette.background.secondary,
      margin: "8px 0",
    },
    nameContainer: {
      alignItems: "center",
      alignSelf: "center",
      display: "flex",
      fontSize: "20px",
      height: "60px",
      gap: "6px",

      "& label": {
        cursor: "pointer",
        fontWeight: 600,
        lineHeight: "30px",
        textAlign: "center",
      },
    },
    dateContainer: {
      direction: "ltr",
      display: "flex",
      gap: "12px",

      "& label": {
        color: theme.palette.text.secondary,
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    hyphen: {
      margin: "0 5px",
    },
    psiLabel: {
      position: "absolute",
      marginTop: "-22px",
      color: theme.palette.text.brand_tertiary,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      marginLeft: "7px",
    },
    dateText: {
      display: "flex",
      gap: "8px",

      "& label": {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: "14px",
      },

      "& span": {
        color: theme.palette.text.tertiary,
        fontWeight: 400,
        fontSize: "14px",
      },
    },
    customMenuList: {
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "8px !important",
      padding: "4px 6px",
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important",
    },
    menuIcon: {
      padding: 0,

      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    option: {
      display: "flex",
      padding: "9px 10px",
      gap: "8px",
      margin: "1px 0",
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.text.secondary,

      "&:hover": {
        backgroundColor: theme.palette.button.bg_hover,
      },
    },
  };
});
