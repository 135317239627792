import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { AutocompleteComponent, Inputs } from "../../../../../Components";
import {  showError } from "../../../../../Helper";

import { ReactComponent as ArrowNarrowRightIcon } from "../../../../../assets/images/defaults/arrow-narrow-right.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/defaults/plus.svg";

export const KeyNearbyNumber = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="px-4 mt-2 w-75">
      <div className="key-nearby-section">
        <div>
          <span className="fw-bold">
            {t(`${translationPath}key-nearby-number`)}
          </span>
        </div>
        <div className="key-nearby-landmarks">
          {state.listOfPropertyRatingKeysNearbiesDto &&
            state.listOfPropertyRatingKeysNearbiesDto.map((item, index) => (
              <div
                className="b-bottom  mt-4 mb-4"
                key={`propertyRatingKeysNearbies${index + 1}`}
              >
                <div
                  className=""
                  key={`propertyRatingKeysNearbiesDtoRef${index}`}
                >
                  <div className="d-flex d-inline mt-2 mb-4 w-100 first-section">
                    <AutocompleteComponent
                      idRef={`VicinityCategory-${index}`}
                      labelValue={"landmark"}
                      multiple={false}
                      selectedValues={item}
                      value={item}
                      isDisabled={true}
                      data={[]}
                      displayLabel={(option) => option.lookupItemName || ""}
                      renderOption={(option) => option.lookupItemName || ""}
                      withoutSearchButton
                      getOptionSelected={(option) =>
                        (option && option.lookupsId) === item?.lookupsId
                      }
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        let updatelist = [
                          ...state.listOfPropertyRatingKeysNearbiesDto,
                        ];
                        updatelist[index] = newValue
                          ? {
                              ...updatelist[index],
                              newValue,
                            }
                          : {
                              ...updatelist[index],
                              lookupsId: null,
                              lookupItemName: null,
                            };
                        setState({
                          id: "listOfPropertyRatingKeysNearbiesDto",
                          value: [...updatelist],
                        });
                      }}
                    />
                  </div>
                  <div className="key-nearby-number mb-2 w-100 mt-2">
                    <div className="mb-2">
                      <Inputs
                        idRef={`From-${index}`}
                        labelValue={t("From")}
                        value={item && item.from}
                        type={"number"}
                         min={1}
                        inputPlaceholde={t("Shared:From")}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputChanged={(event) => {
                          const value = event.target.value;
                          if(value <= 0 )
                            {
                              showError(t(`${translationPath}please-enter-number-greater-than-zero`));
                              return; 
                            }
                          let updatelist = [
                            ...state.listOfPropertyRatingKeysNearbiesDto,
                          ];
                          updatelist[index] = {
                            ...updatelist[index],
                            from: value === "" ? 0 : value,
                          };
                          setState({
                            id: "listOfPropertyRatingKeysNearbiesDto",
                            value: [...updatelist],
                          });
                        }}
                      />
                    </div>
                    <div className="arrow-part">
                      <ArrowNarrowRightIcon />
                    </div>
                    <div className="mb-2">
                      <Inputs
                        idRef={`To-${index}`}
                        labelValue={t("To")}
                        inputPlaceholde={t("To")}
                        type={"number"}
                        min={1}
                        value={item && item.to}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputChanged={(event) => {
                          const value = event.target.value;
                          if(value <= 0 )
                          {
                            showError(t(`${translationPath}please-enter-number-greater-than-zero`));
                            return; 
                          }
                      
                          let updatelist = [
                            ...state.listOfPropertyRatingKeysNearbiesDto,
                          ];
                          updatelist[index] = {
                            ...updatelist[index],
                            to: value === "" ? 0 : value,
                          };
                          setState({
                            id: "listOfPropertyRatingKeysNearbiesDto",
                            value: [...updatelist],
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex d-flex-v-center b-bottom mt-2 mb-2">
                    <div className="px-2">
                      {" "}
                      <span className="">
                        {" "}
                        {t(`${translationPath}rating`)}{" "}
                      </span>{" "}
                    </div>
                    <Rating
                      size="large"
                      name={`RatingKeysNearbies-${index}`}
                      max={10}
                      value={item && item.score}
                      precision={1}
                      onChange={(event, newValue) => {
                        let updatelist = [
                          ...state.listOfPropertyRatingKeysNearbiesDto,
                        ];
                        updatelist[index] = {
                          ...updatelist[index],
                          score: newValue || 0 ,
                        };
                        setState({
                          id: "listOfPropertyRatingKeysNearbiesDto",
                          value: [...updatelist],
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
