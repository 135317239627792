import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { MetroSpinner } from "react-spinners-kit";

import "./LoadingSpinner.scss";

export const LoadingSpinnerSize = {
  large: "large",
  medium: "medium",
  small: "small",
  extraSmall: "extraSmall",
};

export const LoadingSpinnerStyle = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
};

const LoadingSpinner = ({
  sizeVariant = LoadingSpinnerSize.large,
  styleVariant = LoadingSpinnerStyle.primary,
}) => {
  const { isDarkMode } = useSelector((state) => state.theme);

  // Define additional styles based on sizeVariant
  const additionalStyles = {
    fontSize:
      sizeVariant === LoadingSpinnerSize.medium ||
      sizeVariant === LoadingSpinnerSize.large
        ? "18px"
        : "14px",
  };

  return (
    <div
      className={classNames(
        "loadingSpinnerContainer",
        `${sizeVariant}Container`
      )}
      style={{
        gap:
          sizeVariant === LoadingSpinnerSize.medium ||
          sizeVariant === LoadingSpinnerSize.large
            ? "20px"
            : "16px",
      }}
    >
      {styleVariant === "tertiary" ? (
        <MetroSpinner color={isDarkMode ? "#8C734B" : "#7F6944"} />
      ) : (
        <img
          alt={`LoadingSpinner-${sizeVariant}`}
          className={sizeVariant}
          src={`/images/loadingSpinnerIcons/${
            styleVariant + (isDarkMode ? "Dark" : "")
          }.svg`}
        />
      )}

      <label style={additionalStyles}>Loading...</label>
    </div>
  );
};

export default LoadingSpinner;
