import React from 'react';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import { useCopy } from './CopyHelper/useCopy';

// Styles
import './style/Copy.Style.scss';

const CopyToClipboardComponents = ({
  childrenData,
  data,
  labelClaass,
  CustomizationClassName,
  MessageTitle,
  iconCopy,
  CopyDoneShowTime,
  iconCopyDone,
  isNewDesign,
  hideToastify,
  children,
}) => {
  const { CopiedTheValue, CopyFunctionBehavior } = useCopy();

  const handleCopy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    CopyFunctionBehavior(data, MessageTitle, CopyDoneShowTime, hideToastify);
  }

  return (
    <div 
      className={`copy-container ${CustomizationClassName} mx-1`}
      onClick={isNewDesign ? handleCopy : () => {}}
    >
      <span className={`${labelClaass}`}>{childrenData || ''}</span>
      {data && !isNewDesign && (
        <div className='icon-container' id={`id-CopyToClipboardComponents ${data || 1} `}>
          <span
            className={clsx('mdi mdi-', (CopiedTheValue && `copied  ${iconCopyDone}`) || `${iconCopy}`)}
            onClick={handleCopy}
          />
        </div>
      )}
      {children}
    </div>
  );
};

export { CopyToClipboardComponents };

CopyToClipboardComponents.propTypes = {
  iconCopy: PropTypes.string,
  iconCopyDone: PropTypes.string,
  CopyDoneShowTime: PropTypes.number,
  childrenData: PropTypes.string,
  MessageTitle: PropTypes.string,
  data: PropTypes.string,
  CustomizationClassName: PropTypes.string,
  isNewDesign: PropTypes.bool,
  hideToastify: PropTypes.bool,
  children: PropTypes.node,
};

CopyToClipboardComponents.defaultProps = {
  iconCopyDone: 'mdi-check-all',
  iconCopy: 'mdi-content-copy',
  CopyDoneShowTime: 2000, // Time in Milliseconds
  childrenData: undefined,
  MessageTitle: undefined,
  data: undefined,
  labelClaass: '',
  CustomizationClassName: '',
  isNewDesign: false,
  hideToastify: false,
  children: null,
};
// Refactoring Done 2023
