import React from 'react';
import { UnitsFilterStatusEnum } from '../../../../../Enums' ; 

const getValuecolor = (Value) => {
  let textcolor;
  switch (Value) {
    case 'Available':
      textcolor = 'c-success-light';
      break;
    case 'ReservedLeased':
      textcolor = 'globel-bold-style c-primary';
      break;
    case 'Leased':
      textcolor = 'globel-bold-style c-primary';
      break;
    case 'Draft':
      textcolor = 'globel-Gry-style';
      break;
    case 'PendingLease':
      textcolor = 'globel-bold-style c-pending';
      break;
    case 'PendingReserveLease':
      textcolor = 'globel-bold-style c-pending';
      break;
    default:
      textcolor = '';
  }
  return textcolor;
};

export const UnitsLeaseTableHeaderData = [
  {
    id: 1,
    label: 'Id',
    fieldKey: 'unitId',
    input: 'unitId',
    fieldType: 'text' ,
    isSortable: true,
    isHiddenFilter: false,
    isNumber: true,
    component: (item) => (item && item.id) || <span />
  },
  {
    id: 2,
    label: 'Name',
    input: 'UnitName',
    fieldType: 'text' ,
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => (item && item.name) || <span />
  },
  {
    id: 3,
    label: 'Created By',
    fieldType: 'searchField' , 
    input: 'createdBy',
    fieldKey: 'createdBy',
    data:  {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "createdBy",
      displayPath: "createdBy"
   },
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 4,
    label: 'Creation Date',
    input: 'createdOn',
    fieldKey: 'createdOn',
    fieldType: 'alt-date' , 
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
  },
  {
    id: 5,
    label: 'Updated By',
    input: 'updatedBy',
    fieldType: 'searchField' , 
    data:  {
      type: "string",
      title: "Updated By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "updatedBy",
      displayPath: "updatedBy"
    },
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 6,
    label: 'Progress',
    input: 'data_completed',
    fieldType: 'number' , 
    textInputType: 'number',
    fieldKey: 'data_completed',
    key: 'data_completed',
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isSortable: true,
    isNumber: true,
    component: (item) => (item && item.progressWithPercentage) || <span />,
  },
  {
    id: 7,
    label: 'Status',
    isHiddenFilter: false,
    fieldType: 'select' , 
    input: 'StringStatus',
    data : { enum : Object.values(UnitsFilterStatusEnum.lease)} , 
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => (
      <span>
        {(item && item.unitStatus && item.unitStatus.name
          && item.unitStatus.name && (
            <div className={getValuecolor(item.unitStatus.name || '')}>
              {item.unitStatus.name}
            </div>
          )
        )}
      </span>
    ),
  },
  {
    id: 8,
    label: 'Status Date',
    isDefaultFilterColumn: true,
    input: 'StatusDate',
    fieldType: 'alt-date' ,
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    component: (item) => (item && item.statusDate) || <span />,

  },
  {
    id: 9,
    label: 'Updated Owner Date',
    isDefaultFilterColumn: true,
    input: 'LastUpdatedOwner',
    isHiddenFilter: false,
    fieldType: 'alt-date' , 
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY',
    component: (item) => (item && item.updatedOwnerDate) || <span />,
  },
  {
    id: 10,
    label: 'Rent Price',
    isHiddenFilter: true,
    input: 'rentPerYear',
    isSortable: true,
    fieldType: 'text' , 
    isNumber:true , 
    isDefaultFilterColumn: true,
    data : {
      type: "string",
      title: "Rent Per Year",
      iconField: "",
      description: "Rent Details & Pricing",
      specialKey: "currency",
      hasEffectOn: "",
      hasEffectedFrom: "",
      duplicateValidation: "",
      regExp: "",
      errorMsg: "",
      default: "",
      isSearchable: false,
      searchableKey: "rentPerYear",
      displayPath: "rentPerYear"
  },
    component: (item) => (item && item.rent_price_fees) || <span />,
  },
  {
    id: 11,
    label: 'Listing Agent',
    isSortable: true,
    fieldKey: 'listing_agent',
    input: 'rent_listing_agent',
    fieldType: 'searchField' , 
    hideOnAdvanceSearch : true , 
    isDefaultFilterColumn: true,
    component: (item) => (
      <span>
        {(item && item.details && item.details.map((el) => el.title === 'listing-agent' && el.value)) || (
          <span />
        )}
      </span>
    ),
    data:  {
      type: "string",
      title: "Listing Agent",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "listing_agent",
      displayPath: "listing_agent"
   }
  },
  {
    id: 12,
    label: 'Lease Publish Date',
    isDefaultFilterColumn: false,
    input: 'lastLeasePublishDate',
    isHiddenFilter: false,
    fieldType: 'alt-date' , 
    isDate: true,
    isSortable: true,
  },
  {
    id: 13,
    label: 'Transacted By',
    input: 'statusCreatedByName',
    isHiddenFilter: false,
    isDefaultFilterColumn: false,
    isSortable: true, 
    fieldType: 'searchField' ,
    data: {
      type: "string",
      title: "Status Created By Name",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "statusCreatedByName",
      displayPath: "statusCreatedByName"
   } 
  },
  {
    id: 14,
    label: 'isBulkUpload',
    input: 'isBulkUpload',
    isHiddenFilter: false,
    fieldType: 'select' ,
    data : { enum :['Yes' , 'No'] } ,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => <div>{item?.isBulkUpload ? 'Yes' : 'No'}</div>
  },
  {
    id: 15,
    label: 'Last Activty Type Name',
    input: 'lastActivtyTypeName',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: 'searchField' ,
    data: {
      type: "string",
      title: "Status Created By Name",
      description: "General Information",
      searchKey: "activtyType",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "statusCreatedByName",
      displayPath: "statusCreatedByName"
   } 
  },
   {
    id: 16,
    label: 'lastActivityDate',
    input: 'lastActivityDate',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    isDate: true,
    fieldType: 'alt-date' , 
  },
  {
    id: 17,
    label: 'is-Shared-Unit',
    input: 'isSharedUnit',
    isHiddenFilter: false,
    fieldType: 'select' ,
    data : { enum :['Yes' , 'No'] } ,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => <div>{item?.isSharedUnit ? 'Yes' : 'No'}
    </div>
  },
];
