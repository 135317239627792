import moment from 'moment';

function dateWithMonthsDelay(newDate, months) {
  const date = new Date(newDate);
  date.setMonth(date.getMonth() + months);
  return date;
}

export const ListingDateRule = async (id, item, itemList, v, values, setData) => {
  console.log('ListingDateRule' ) ; 
  if (id === 'listing_expiry-period') {
       if (!v) {
        setTimeout(() => {
        }, 100);
      } else {
            const listingDateValue = values && values.listing_date && new Date(values.listing_date);
            const addMonths = v && v.lookupItemName === '1 Month' ? 1 : 3;
            const dateWithMonths = dateWithMonthsDelay(listingDateValue, addMonths);
            const expiryDate = moment(dateWithMonths);
            setData('listing_expiry-date', expiryDate);
            setData('listing_expiry_date', expiryDate);
        }
  } else if (id === 'rent_listing-expiry-period') {
    if (!v) {
     setTimeout(() => {
     }, 100);
    } else {
      const rentListingDateValue = values && values.rent_listing_date && new Date(values.rent_listing_date);
      const addMonths = v && v.lookupItemName === '1 Month' ? 1 : 3;
      const dateWithMonths = dateWithMonthsDelay(rentListingDateValue, addMonths);
      const rentExpiryDate = moment(dateWithMonths);
      setData('rent_listing-expiry-date', rentExpiryDate);
      setData('rent_listing_expiry_date', rentExpiryDate);
     }
  }
  else if(item.field.id === 'listing_date' &&  values && values.listing_expiry_period && v)
    {    
      const listingDate = v ;
      const  listingExpiryPeriod  = values.listing_expiry_period ; 
      const newValue =  (listingDate && new Date(listingDate)) || null ;
      const addMonths = listingExpiryPeriod && listingExpiryPeriod.lookupItemName === '1 Month' ? 1 : (listingExpiryPeriod && listingExpiryPeriod.lookupItemName === '3 Months' ? 3 : 0);
      const dateWithMonths =  newValue ? dateWithMonthsDelay(newValue, addMonths) : null;
      const expiryDate = (dateWithMonths && moment(dateWithMonths)) ;
      setTimeout(() => 
      {
        setData('listing_expiry_date' , expiryDate || {});
        setData('listing_expiry-date' , expiryDate || {});

      }, 100);
     
      
    } 
    else if(item.field.id === 'listing_date' &&  values && values.listing_expiry_period && !v) 
    {
      debugger ;
      setTimeout(() => 
        {
          setData('listing_expiry-period' ,null);
          setData('listing_expiry_period' , {});
      }, 75);
    } 
    else if(item.field.id === 'rent_listing-date' &&  values && values.rent_listing_expiry_period && v)
      {    
        const listingDate = v ;
        const  rentListingExpiryPeriod  = values.rent_listing_expiry_period ; 
        const newValue =  (listingDate && new Date(listingDate)) || null ;
        const addMonths = rentListingExpiryPeriod && rentListingExpiryPeriod.lookupItemName === '1 Month' ? 1 : (rentListingExpiryPeriod && rentListingExpiryPeriod.lookupItemName === '3 Months' ? 3 : 0);
        const dateWithMonths =  newValue ? dateWithMonthsDelay(newValue, addMonths) : null;
        const expiryDate = (dateWithMonths && moment(dateWithMonths)) ;
        setTimeout(() => 
        {
          setData('rent_listing_expiry_date' , expiryDate);
          setData('rent_listing-expiry-date' , expiryDate);
        }, 100);
      }
       else if(item.field.id === 'rent_listing-date' &&  values && values.rent_listing_expiry_period && !v)
        {  
          setTimeout(() => 
          {  
          setData('rent_listing-expiry-period' , null );
          setData('rent_listing_expiry_period' , null || {} );
        }, 100);
        } 
};

export const ListingDateRule2 = async (item, value, onValueChanged, allItems, allItemsValues) => {
  if (item.field.id === 'listing_expiry_period') {
    if (!value) 
      return;
  
    const listingDate = allItemsValues.listing_date;
    const newValue =  (listingDate && new Date(listingDate)) || null ;
    const addMonths = value && value.lookupItemName === '1 Month' ? 1 : (value && value.lookupItemName === '3 Months' ? 3 : 0);
    const dateWithMonths =  newValue ? dateWithMonthsDelay(newValue, addMonths) : null;
    const expiryDate = (dateWithMonths && moment(dateWithMonths)) ;
    onValueChanged(expiryDate, 0, 'listing_expiry_date');

  } else if (item.field.id === 'rent_listing_expiry_period') {
    if (!value) 
      return;
    const leaseListingDate = (allItemsValues.rent_listing_date);
    const newValue = (leaseListingDate && new Date(leaseListingDate)) || null;
    const addMonths = value && value.lookupItemName === '1 Month' ? 1 : 3;
    const dateWithMonths = newValue ? dateWithMonthsDelay(newValue, addMonths) : null;
    const expiryDate = dateWithMonths && moment(dateWithMonths);
    onValueChanged(expiryDate, 0, 'rent_listing_expiry_date');
  }
  else if(item.field.id === 'listing_date' &&  allItemsValues && allItemsValues.listing_expiry_period && value)
    {    
      const listingDate = value ;
      const  listingExpiryPeriod  = allItemsValues.listing_expiry_period ; 
      const newValue =  (listingDate && new Date(listingDate)) || null ;
      const addMonths = listingExpiryPeriod && listingExpiryPeriod.lookupItemName === '1 Month' ? 1 : (listingExpiryPeriod && listingExpiryPeriod.lookupItemName === '3 Months' ? 3 : 0);
      const dateWithMonths =  newValue ? dateWithMonthsDelay(newValue, addMonths) : null;
      const expiryDate = (dateWithMonths && moment(dateWithMonths)) ;
      onValueChanged(expiryDate, 0, 'listing_expiry_date');
      
    } 
    else if(item.field.id === 'listing_date' &&  allItemsValues && allItemsValues.listing_expiry_period && !value)    
        onValueChanged(null, 0, 'listing_expiry_period');
      
    else if(item.field.id === 'rent_listing_date' &&  allItemsValues && allItemsValues.rent_listing_expiry_period && value)
      {    
        const listingDate = value ;
        const  rentListingExpiryPeriod  = allItemsValues.rent_listing_expiry_period ; 
        const newValue =  (listingDate && new Date(listingDate)) || null ;
        const addMonths = rentListingExpiryPeriod && rentListingExpiryPeriod.lookupItemName === '1 Month' ? 1 : (rentListingExpiryPeriod && rentListingExpiryPeriod.lookupItemName === '3 Months' ? 3 : 0);
        const dateWithMonths =  newValue ? dateWithMonthsDelay(newValue, addMonths) : null;
        const expiryDate = (dateWithMonths && moment(dateWithMonths)) ;
        onValueChanged(expiryDate, 0, 'rent_listing_expiry_date');

      }
       else if(item.field.id === 'rent_listing_date' &&  allItemsValues && allItemsValues.rent_listing_expiry_period && !value)
        {    
          onValueChanged(null, 0, 'rent_listing_expiry_period');
        } 
};

export const ListingExpiryDateRule1 = async (item, setData) => {
  if (item.field.id === 'listing_expiry-date') {
    setTimeout(() => {
      setData('listing_expiry_period', {});
      setData('listing_expiry-period', {});
    }, 100);
  } else if (item.field.id === 'rent_listing-expiry-date') {
    setTimeout(() => {
      setData('rent_listing_expiry_period', {});
      setData('rent_listing-expiry-period', {});
    }, 100);
  }
};

export const ListingExpiryDateRule2 = (item, onValueChanged) => {
  if (item.field.id === 'listing_expiry_date')
      onValueChanged(null, 0, 'listing_expiry_period');

  else if (item.field.id === 'rent_listing_expiry_date')
    onValueChanged(null, 0, 'rent_listing_expiry_period');
};

export const ListingDate = (id, itemList, value) => {
  if (id === 'listing_date') {
    const listingExpiryDateIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'listing_expiry_date')
    );
    if (listingExpiryDateIndex !== -1)
      itemList[listingExpiryDateIndex].data.minDate = moment(value).add(1, 'days');
  }
  if (id === 'rent_listing_date') {
    const rentListingExpiryDateIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'rent_listing_expiry_date')
    );
    if (rentListingExpiryDateIndex !== -1)
      itemList[rentListingExpiryDateIndex].data.minDate = moment(value).add(1, 'days');
  }
};

export const ListingDate1 = (id, itemList, value) => {
  if (id === 'listing_date') {
    const listingExpiryDateIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'listing_expiry-date')
    );
    if (listingExpiryDateIndex !== -1)
      itemList[listingExpiryDateIndex].data.minDate = moment(value).add(1, 'days');
  }
  if (id === 'rent_listing-date') {
    const rentListingExpiryDateIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'rent_listing-expiry-date')
    );
    if (rentListingExpiryDateIndex !== -1)
      itemList[rentListingExpiryDateIndex].data.minDate = moment(value).add(1, 'days');
  }
};
