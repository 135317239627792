export const ContactsPermissions = {
  AddNewContact: {
    permissionsId: '896df1f9-e477-4c4a-32ac-08d91a092746',
    permissionsName: 'Add new contact',
    componentsId: null,
    components: null,
  },
  MergeContacts: {
    permissionsId: '17aa1eff-4813-43f9-32ad-08d91a092746',
    permissionsName: 'Merge contacts',
    componentsId: null,
    components: null,
  },
  ImportContact: {
    permissionsId: '2ae63082-0538-4a38-32ae-08d91a092746',
    permissionsName: 'Import contact',
    componentsId: null,
    components: null,
  },
  ViewAndSearchContacts: {
    permissionsId: '5fa25637-61d0-41f5-32af-08d91a092746',
    permissionsName: 'View and Search contacts',
    componentsId: null,
    components: null,
  },
  ViewContactInformation: {
    permissionsId: '6fdf031d-d336-4088-32b0-08d91a092746',
    permissionsName: 'View contact  details',
    componentsId: null,
    components: null,
  },
  EditContactInformation: {
    permissionsId: 'b48de1f7-1afd-42cb-32b1-08d91a092746',
    permissionsName: 'Edit contact details ',
    componentsId: null,
    components: null,
  },
  ViewAssociatedContacts: {
    permissionsId: 'f82fdf25-75da-4a7f-32b2-08d91a092746',
    permissionsName: 'View associated contacts for contact',
    componentsId: null,
    components: null,
  },
  ViewLeads: {
    permissionsId: '297c7885-a2fa-4dee-32b3-08d91a092746',
    permissionsName: 'View leads for contact',
    componentsId: null,
    components: null,
  },
  ViewUnit: {
    permissionsId: '596e1bdd-b645-422b-32b4-08d91a092746',
    permissionsName: 'View unit for contact',
    componentsId: null,
    components: null,
  },
  ViewActivity: {
    permissionsId: '77609fe4-0c44-4d61-32b5-08d91a092746',
    permissionsName: 'View activity for contact',
    componentsId: null,
    components: null,
  },
  ViewDocuments: {
    permissionsId: '9409f801-caeb-45a5-32b6-08d91a092746',
    permissionsName: 'View documents for contact',
    componentsId: null,
    components: null,
  },
  ViewUnitHistoryTransaction: {
    permissionsId: '27ad9d85-3820-40be-32b7-08d91a092746',
    permissionsName: 'View unit history transaction for contact',
    componentsId: null,
    components: null,
  },
  ViewUsersAccessOnContact: {
    permissionsId: '60d7c342-6b18-4730-32b8-08d91a092746',
    permissionsName: 'View users access on contact',
    componentsId: null,
    components: null,
  },
  ViewDuplicateForThisContact: {
    permissionsId: 'f0570c91-be2e-474d-32b9-08d91a092746',
    permissionsName: 'View duplicate for this contact',
    componentsId: null,
    components: null,
  },
  ReassignLead: {
    permissionsId: 'ddcac9bb-bb2e-47a2-1ccd-08dabfc53c80',
    permissionsName: 'Reassign Lead',
    componentsId: null,
    components: null,
  },
  CloneLead: {
    permissionsId: 'e895465f-e663-4c6c-1cce-08dabfc53c80',
    permissionsName: 'Clone Lead',
    componentsId: null,
    components: null,
  },
  CloseLead: {
    permissionsId: '910cff3b-5405-4222-1ccf-08dabfc53c80',
    permissionsName: 'Close Lead',
    componentsId: null,
    components: null,
  },
  AddLead: {
    permissionsId: '771a474f-4e4e-4557-1cd0-08dabfc53c80',
    permissionsName: 'Add Lead',
    componentsId: null,
    components: null,
  },
  AddDocumentsForContacts: {
    permissionsId: 'f1a17bc9-0d4f-4865-2e5a-08db604be055',
    permissionsName: 'Add Documents For Contacts',
    description: null,
    componentsId: null,
    components: null,
  },
  EditDocumentsForContacts: {
    permissionsId: 'dc4b91c0-9ef7-4143-2e5b-08db604be055',
    permissionsName: 'Edit Documents For Contacts',
    description: null,
    componentsId: null,
    components: null,
  },
  DeleteDocumentsFromContacts: {
    permissionsId: '3d721eb6-3a4f-43e1-2e5c-08db604be055',
    permissionsName: 'Delete Documents From Contacts',
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewActivity: {
    permissionsId: '71995b81-1538-4d2c-286e-08dbaf79a1bb',
    permissionsName: 'Add New Activity',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewSourceFileTab: {
    permissionsId: 'f33d8b99-a6a5-4c09-2b13-08dbca2fdea8',
    permissionsName: 'View Source File Tab',
    description: null,
    componentsId: null,
    components: null,
  },

  ReassignSellerLead: {
    permissionsId: 'f422ec0d-3a48-4bad-8871-08dc0129f7d6',
    permissionsName: 'Reassign Seller Lead',
    description: null,
    componentsId: null,
    components: null,
  },

  ViewTasksForContact: {
    permissionsId: '9d444f06-7282-47fe-19b0-08dc313da57c',
    permissionsName: 'View Tasks For Contact',
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewTask: {
    permissionsId: '0a5d9234-fbe1-4786-19b1-08dc313da57c',
    permissionsName: 'Add New Task',
    description: null,
    componentsId: null,
    components: null,
  },
  EditAngryBirdField: {
    permissionsId: 'e8b3f832-f7d5-4ff5-19c0-08dc313da57c',
    permissionsName: 'Edit Angry Bird Field',
    description: null,
    componentsId: null,
    components: null,
  },
  EditHighProfileManagement: {
    permissionsId: 'ccc793ac-7611-45bc-19c1-08dc313da57c',
    permissionsName: 'Edit High Profile Management',
    description: null,
    componentsId: null,
    components: null,
  },
  ShareContactDocuments: {
    permissionsId: '3445b6da-5edf-4d24-c4ae-08dc683b0c43',
    permissionsName: 'Share Contact Documents',
    description: null,
    componentsId: null,
    components: null,
  },
  EditContactSourceFile: {
    permissionsId: '1eda5343-2e72-47a5-c4af-08dc683b0c43',
    permissionsName: 'Edit Contact Source File',
    description: null,
    componentsId: null,
    components: null,
  },
  ContactDuplications: {
    permissionsId: '0ecce1e7-da3a-4ab8-c4b3-08dc683b0c43',
    permissionsName: 'Contact Duplications',
    description: null,
    componentsId: null,
    components: null,
  },
  BulkUpdate: {
    permissionsId: 'b35c95b4-63b1-4f0c-7961-08dcaf947ea8',
    permissionsName: 'Bulk Update',
    description: null,
    componentsId: null,
    components: null,
  },
  ChangeMainConsentStatus :{
     permissionsId: "3ae05647-3fe5-4240-7964-08dcaf947ea8",
     permissionsName: "Change Main Consent Status",
     description: null,
     componentsId: null,
     components : null
},
ChangeAssetsConsentStatus :{
    permissionsId: "1d380885-9999-42f2-7965-08dcaf947ea8",
    permissionsName: "Change Assets Consent Status",
    description: null,
    componentsId: null,
    components: null
},
ChangeDNCRStatus : {
     permissionsId: "42b7d581-0e72-4cf4-7966-08dcaf947ea8",
     permissionsName: "Change DNCR Status",
     description: null,
     componentsId: null,
     components: null
} , 
DeveloperDocumentsTab : {
  permissionsId: "12709aea-0412-4057-7967-08dcaf947ea8",
  permissionsName: "Developer Documents Tab",
  description: null,
  componentsId: null,
  components: null
},
ViewConsentDNCRTab : {
  permissionsId: "103b89fe-3c45-4279-7969-08dcaf947ea8",
  permissionsName: "View Consent & DNCR Tab",
  description: null,
  componentsId: null,
  components: null
},
PullToContactOpportunity :{
  permissionsId: "37c73202-0d1d-4ad7-796a-08dcaf947ea8",
  permissionsName: "Pull To Contact Opportunity",
  description: null,
  componentsId: null,
  components: null
}
};
