import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Inputs, PaginationComponent, Spinner, PermissionsComponent, SelectComponet
} from '../../../Components';
import { ActionsEnum, TableActions } from '../../../Enums';
import { GlobalHistory, bottomBoxComponentUpdate, returnPropsByPermissions } from '../../../Helper';
import { GetAllSystemTemplateFolders, GetAllSystemTemplates } from '../../../Services';
import { TemplatesPermissions } from '../../../Permissions';
import { TemplateDeleteDialog, TemplatesManagementDialog } from './Dialogs';
import { useTitle } from '../../../Hooks';
import { TemplatesTemplatebuilderComponentPreviewDialog } from './Dialogs/TemplatesPreviewDialog/TemplatesTemplatebuilderComponentPreviewDialog';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { TeamplateFolderView } from './TeamplateFolderView';
import { TemplatesCardsComponent } from './Sections/TemplatesCardsComponent/TemplatesCardsComponent';
import { MoveToFolderDialog } from './Dialogs/MoveToFolderDialog/MoveToFolderDialog';
import { TemplateCreateFolderDialog } from './Dialogs/TemplateCreateFolderDialog/TemplateCreateFolderDialog';

const parentTranslationPath = 'TemplatesView';
const translationPath = '';
export const TemplatesView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  useTitle(t(`${translationPath}templates-builder`));
  const dispatch = useDispatch();
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isOpenMoveToFolde, setisOpenMoveToFolde] = useState(false);
  const [DatawithoutFolder, setDatawithoutFolder] = useState([]);

  const [isOpenManagementDialog, setIsOpenManagementDialog] = useState(false);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [FolderData, setFolderData] = useState([]);
  const [DeleteDialogTYPE, SetDeleteDialogTYPE] = useState('item');
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isEditName, setisEditName] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.filterBy) || 'CreatedOn',
    orderBy: (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.orderBy) || 2,
  });
  const [dateFilter, setDateFilter] = useState(
    (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.startDate && orderFilter.TemplatesBuilder.endDat !== null) &&
    (
      {
        startDate: new Date(orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.startDate || null),
        endDate: new Date(orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.endDate || null),
        key: new Date(orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.key || null),
      }) || dateRangeDefault
  );
  const [orderBy, setOrderBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: 'CreatedOn', orderBy: 2 });
  const [filter, setFilter] = useState(
    {
      search: (orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.search) || '',
      pageIndex: 1,
      pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
      filterBy: orderBy.filterBy,
      orderBy: orderBy.orderBy,
      fromDate: dateFilter.startDate || null,
      toDate: dateFilter.endDate || null,
    }
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        TemplatesBuilder: {
          ...orderFilter.TemplatesBuilder,
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setFilter((item) => ({ ...item, orderBy: selectedOrderBy.orderBy, filterBy: selectedOrderBy.filterBy }));
  };

  const [searchInput, setSearchInput] = useState((orderFilter && orderFilter.TemplatesBuilder && orderFilter.TemplatesBuilder.search) || '');
  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 1, search }));
    }, 700);
    setSearchInput(search);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        TemplatesBuilder: {
          ...orderFilter.TemplatesBuilder,
          search,
        },
      })
    );
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex: pageIndex + 1, search: searchInput }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item, pageIndex: 1, pageSize, search: ''
    }));
    setSearchInput('');
  };
  const [templates, setTemplates] = useState({
    result: [],
    totalCount: 0,
  });
  useEffect(() => {
    if (returnPropsByPermissions(TemplatesPermissions.ViewTemplates.permissionsId)) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex - 1}
          pageSize={filter.pageSize}
          totalCount={templates.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );
  const onFooterActionsClicked = (actionEnum, activeData) => {
    if (actionEnum === TableActions.edit.key || actionEnum === ActionsEnum.reportEdit.key) {
      setIsOpenManagementDialog(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.view.key || actionEnum === ActionsEnum.preview.key) {
      setIsOpenPreviewDialog(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.check.key) {
      setisOpenMoveToFolde(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.delete.key || actionEnum === ActionsEnum.delete.key) {
      setIsOpenDeleteDialog(true);
      setActiveItem(activeData);
      SetDeleteDialogTYPE('item');
    }
  };

  const onActionsClickedFolder = (actionEnum, activeData) => {
    if (actionEnum === TableActions.edit.key) {
      setIsOpenCreateDialog(true);
      setisEditName(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.view.key || actionEnum === ActionsEnum.preview.key) {
      setIsOpenPreviewDialog(true);
      setActiveItem(activeData);
    } else if (actionEnum === TableActions.openFile.key)
      GlobalHistory.push(`/home/templates/OpenFile?id=${activeData.systemTemplateFolderId}&systemTemplateFolderName=${activeData.systemTemplateFolderName}`);
    else if (actionEnum === TableActions.delete.key || actionEnum === ActionsEnum.delete.key) {
      setIsOpenDeleteDialog(true);
      setActiveItem(activeData);
      SetDeleteDialogTYPE('Folder');
    }
  };

  const GetAllSystemTemplateFoldersAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllSystemTemplateFolders(1, 50);
    if (!(res && res.status && res.status !== 200))
      setFolderData((res && res.result) || []);

    else setFolderData([]);
    setIsLoading(false);
  }, []);

  const getAllTemplates = useCallback(async () => {
    if (returnPropsByPermissions(TemplatesPermissions.ViewTemplates.permissionsId)) {
      setIsLoading(true);
      const res = await GetAllSystemTemplates(filter);
      if (!((res && res.data && res.data.ErrorId) || !res)) {
        const DatawithoutFolde = (res && res.result.filter((item) => item.systemTemplateFolderVM === null));
        setTemplates({
          result: res.result || [],
          totalCount: res.totalCount || 0,
        });
        setDatawithoutFolder(DatawithoutFolde);
      } else {
        setTemplates({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoading(false);
    }
  }, [filter]);
  useEffect(() => {
    getAllTemplates();
    GetAllSystemTemplateFoldersAPI();
  }, [filter]);

  return (
    <>
      <div className='templates-wrapper TemplatesBuilder- view-wrapper '>
        <div className='d-flex-column templates-scation-search'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='section px-2'>
                <PermissionsComponent
                  permissionsList={Object.values(TemplatesPermissions)}
                  permissionsId={TemplatesPermissions.AddNewTemplate.permissionsId}
                >
                  <ButtonBase
                    onClick={() => setIsOpenManagementDialog(true)}
                    className='btns theme-solid bg-primary'
                  >
                    <span className='mdi mdi-plus   mx-1 ' />
                    {t(`${translationPath}add-new-template`)}
                  </ButtonBase>
                  <div className='btns-folder'>
                    <ButtonBase
                      onClick={() => { setIsOpenCreateDialog(true); setisEditName(false); }}
                      className='btns  bg-waiting'
                    >
                      <span className='mdi mdi-folder-plus mx-1 ' />
                      {t(`${translationPath}add-new-folder`)}
                    </ButtonBase>
                  </div>
                </PermissionsComponent>
              </div>
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='w-100 p-relative d-flex  '>
                    <PermissionsComponent
                      permissionsList={Object.values(TemplatesPermissions)}
                      permissionsId={TemplatesPermissions.ViewTemplates.permissionsId}
                    >
                      <Inputs
                        idRef='templatesSearchRef'
                        label='filter'
                        value={searchInput}
                        beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                        onInputChanged={(e) => {
                          searchHandler(e.target.value);
                        }}
                        inputPlaceholder='search-templates'
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                      />
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateFilter(dateRangeDefault);

                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              TemplatesBuilder: {
                                startDate: null,
                                endDate: null,
                              },
                            })
                          );

                          setFilter((item) => ({
                            ...item,
                            fromDate: null,
                            toDate: null
                          }));
                        }}
                        ranges={[dateFilter]}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              TemplatesBuilder: {
                                ...orderFilter.TemplatesBuilder,
                                startDate: selectedDate.selection && selectedDate.selection.startDate,
                                endDate: selectedDate.selection && selectedDate.selection.endDate,
                              },
                            })
                          );
                          setDateFilter({
                            startDate: selectedDate.selection && selectedDate.selection.startDate,
                            endDate: (selectedDate.selection && selectedDate.selection.endDate),
                            key: 'selection',
                          });
                          setFilter((item) => ({
                            ...item,
                            fromDate: selectedDate.selection && selectedDate.selection.startDate,
                            toDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                          }));
                        }}
                      />
                    </PermissionsComponent>
                  </div>
                  <div className='sc-search d-flex px-2' />
                </div>
              </div>
            </div>

            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}templates-filter`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'CreatedOn', filterBy: 'created-on' },
                      { id: 'UpdateOn', filterBy: 'last-updated' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    onClick={orderBySubmitted}
                    disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className='SystemTemplate'>
          <Spinner isActive={isLoading} />
          <div className='w-100'>
            <TeamplateFolderView
              data={FolderData}
              onActionsClickedFolder={(actionEnum, activeData) => onActionsClickedFolder(actionEnum, activeData)}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
            {DatawithoutFolder && (
              <TemplatesCardsComponent
                data={DatawithoutFolder}
                onFooterActionsClicked={(actionEnum, activeData) => onFooterActionsClicked(actionEnum, activeData)}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )}
          </div>
        </div>
        {isOpenCreateDialog && (
          <TemplateCreateFolderDialog
            isOpen={isOpenCreateDialog}
            isEdit={isEditName}
            FolderId={activeItem}
            isOpenChanged={() => { setIsOpenCreateDialog(false); }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            reloadData={() => { GetAllSystemTemplateFoldersAPI(); }}
          />
        )}
        <MoveToFolderDialog
          isOpen={isOpenMoveToFolde}
          activeItem={activeItem}
          reloadData={() => {
            setFilter((item) => ({ ...item, pageIndex: 0 }));
            setActiveItem(null);
            setIsOpenDeleteDialog(false);
            GetAllSystemTemplateFoldersAPI();
          }}
          isOpenChanged={() => { setisOpenMoveToFolde(false); }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        {isOpenManagementDialog && isOpenManagementDialog && (
          <TemplatesManagementDialog
            activeItem={activeItem}
            isOpen={isOpenManagementDialog}
            onSave={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setIsOpenManagementDialog(false);
              setActiveItem(null);
            }}
            isOpenChanged={() => {
              setIsOpenManagementDialog(false);
              setActiveItem(null);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}

        {isOpenPreviewDialog && (
          <TemplatesTemplatebuilderComponentPreviewDialog
            activeItem={activeItem}
            isOpen={isOpenPreviewDialog}
            isOpenChanged={() => {
              setIsOpenPreviewDialog(false);
              setActiveItem(null);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {isOpenDeleteDialog && (
          <TemplateDeleteDialog
            activeItem={activeItem}
            isOpen={isOpenDeleteDialog}
            DeleteDialogType={DeleteDialogTYPE}
            isOpenChanged={() => {
              setIsOpenDeleteDialog(false);
              setActiveItem(null);
            }}
            reloadData={() => {
              setFilter((item) => ({ ...item, pageIndex: 0 }));
              setActiveItem(null);
              setIsOpenDeleteDialog(false);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
    </>
  );
};
