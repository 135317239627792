import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    commentContainer: {
      display: "flex",
      gap: "12px",
    },
    comment: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      marginBottom: "2px",
    },
    commentDetails: {
      display: "flex",
      gap: "6px",
      alignItems: "center",
    },
    commentName: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      color: theme.palette.text.primary,
    },
    commentDate: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
      color: theme.palette.text.text_quarterary,
    },
    avatar: {
      alignSelf: "flex-end",
      width: "40px",
      height: "40px",
      border: `0.75px solid ${theme.palette.border.avatarContrast}`,
    },
    defaultAvatar: {
      alignSelf: "flex-end",
      backgroundColor: "#F2F4F7", // avatar background
      border: `0.75px solid ${theme.palette.border.secondary}`,
      height: "40px",
      width: "40px",

      "& img": {
        height: "25px",
        width: "25px",
      },
    }
  }
});
