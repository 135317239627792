import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  contactPopupContainerStyle: {
    direction: "ltr",
    borderRadius: "10px",
    background: theme.palette.background.paper,
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    display: "flex",
    width: "320px",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px 20px",
  },
  actions: {
    display: "flex",
    gap: "12px",
  },
  h4: {
    color: theme.palette.text.primary,
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    margin: "0",
  },
  headerCell: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  popupHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  customInput: {
    width: "282px",
    height: "44px",
    display: "flex",
    margin: "16px 0 32px",
    color: theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.primary}`,
    background: theme.palette.background.paper,
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  },
  BasicInput: {
    height: "40px",
    width: "180px",
    border: "none",
    backgroundColor: "transparent",
  },
  placeholder: {
    color: theme.palette.text.placeholder,
    fontWeight: 400,
  },
}));
