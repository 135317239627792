import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { useIsDesktop } from "../../../Hooks";

// styles
import useStyles from "./styles";

function VerticalLayout({
  header,
  footer,
  sideNav,
  mobileNav,
  mobileSideNav,
  children,
}) {
  const { isExpanded, hoverExpanded, sideNavWidth, isMobileSideNavOpen } =
    useVerticalNav();

  const { isDesktop } = useIsDesktop();

  // Use styles and pass the current state as props
  const styles = useStyles({ isExpanded, hoverExpanded, sideNavWidth });

  const mobileNavRef = useRef(null);

  const [mobileNavHeight, setMobileNavHeight] = useState(0);
  const { tablePopoverContent, setTablePopoverContent } = useVerticalNav();

  useEffect(() => {
    if (mobileNavRef.current) {
      setMobileNavHeight(mobileNavRef.current.clientHeight);
    }
  }, [mobileNavRef.current]);

  return (
    <div className={styles.mainContainer}>
      {!isDesktop && isMobileSideNavOpen && mobileSideNav}
      <div
        className={styles.layoutContainer}
        style={
          !isDesktop && mobileNavHeight
            ? { paddingBottom: mobileNavHeight + 1 }
            : {}
        }
      >
        {sideNav || null}
        <div
          className={styles.contentWrapper}
          onScroll={() => {
            if (tablePopoverContent) {
              setTablePopoverContent(null);
            }
          }}
        >
          {header || null}
          {/* Content */}
          {children}
          {footer || null}
        </div>
      </div>
      {!isDesktop && React.cloneElement(mobileNav, { ref: mobileNavRef })}
    </div>
  );
}

VerticalLayout.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  sideNav: PropTypes.node,
  mobileNav: PropTypes.node,
  mobileSideNav: PropTypes.node,
  children: PropTypes.node,
};

VerticalLayout.defaultProps = {
  header: null,
  footer: null,
  sideNav: null,
  mobileNav: null,
  mobileSideNav: null,
  children: null,
};

export default VerticalLayout;
