export const LeadsLeasePermissions = {
  ViewAndSearchInLeaseLeads: {
    permissionsId: '1b14ea26-912f-4fff-ca0b-08d92ff25bf0',
    permissionsName: 'View and search in lease leads',
    componentsId: null,
    components: null,
  },
  ViewLeadDetails: {
    permissionsId: 'a2a7d166-38c3-4346-ca0c-08d92ff25bf0',
    permissionsName: 'View lead details',
    componentsId: null,
    components: null,
  },
  ViewMatchingUnits: {
    permissionsId: '932ffbca-567e-4d56-ca0d-08d92ff25bf0',
    permissionsName: 'View Matching Units',
    componentsId: null,
    components: null,
  },
  AddNewLead: {
    permissionsId: 'c8b6a642-c95c-4f7d-ca0e-08d92ff25bf0',
    permissionsName: 'Add new lead',
    componentsId: null,
    components: null,
  },
  EditLeadDetails: {
    permissionsId: 'c016102b-1aae-4882-ca0f-08d92ff25bf0',
    permissionsName: 'Edit lead details',
    componentsId: null,
    components: null,
  },

  CloneLead: {
    permissionsId: 'a8c2fa96-b795-4de9-475b-08d9aa7e8df8',
    permissionsName: 'Clone Lead',
    componentsId: null,
    components: null
  },

  GetAgentLeadAssigmentHistory : {
    permissionsId: '53d7a634-aeb1-48ce-a889-08da6e2b177d',
    permissionsName: 'Get Agent Lead Assigment History',
    componentsId: null,
    components: null
  },
  ImportLead :  {
    permissionsId: '8577d00d-ebdf-457e-a88c-08da6e2b177d',
    permissionsName: 'Import Lead',
    componentsId: null,
    components: null
  },
  CloseLead : {
    permissionsId: '409697b4-3a3f-4205-a88f-08da6e2b177d',
    permissionsName: 'Close Lead',
    componentsId: null,
    components: null
  },
  ReassignLead :{
    permissionsId: '67990e5e-0479-484d-a893-08da6e2b177d',
    permissionsName: 'Reassign Lead',
    componentsId : null,
    components : null
  },
  GetActivitiesForLead : {
    permissionsId: 'e3cbae9c-2f5d-4c88-a896-08da6e2b177d',
    permissionsName: 'Get Activities For Lead',
    componentsId: null,
    components: null
  },

  SendToRotation : {
    components : null , 
    componentsId : null , 
    permissionsId : 'e52c62cd-a0d4-42e4-8399-08db0f594284' , 
    permissionsName : 'Send To Rotation' 
  } , 
  AddNewActivity :  {
    permissionsId: "11a9f20c-263f-4c8c-2872-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null
 } , 
 ReassignSellerLead : {
  permissionsId: "76e69e99-b01e-4475-8873-08dc0129f7d6",
  permissionsName: "Reassign Seller Lead",
  description: null,
  componentsId: null,
  components: null
} , 
ViewTasksForLead :{
   permissionsId: "e4d61464-30c4-41ad-19ba-08dc313da57c",
   permissionsName: "View Tasks For Lead",
   description: null,
   componentsId: null,
   components: null
},
AddNewTask :{
  permissionsId: "f8aa45e7-b532-4e52-19bb-08dc313da57c",
  permissionsName: "Add New Task",
  description: null,
  componentsId: null,
  components: null
} , 
SendToTheLeadsPool :{
  permissionsId: "e9c4834e-e413-4308-c4c2-08dc683b0c43",
  permissionsName: "Send To The Leads Pool",
  description: null,
  componentsId: null,
  components: null
},
SearchAndMatch :{
   permissionsId: "bfb61a7d-c8ea-4b26-796d-08dcaf947ea8",
   permissionsName: "Search & Match",
   description: null,
   componentsId: null,
   components: null
}

};
