import React from "react";
import { Card } from "../../../../../../Components";
import { OverviewCard } from "../OverviewCard";

// Styles
import useStyles from "./styles";

function SocialProfiles() {
  const styles = useStyles();

  return (
    <OverviewCard title="Social profiles">
      <div>Social Profiles</div>
    </OverviewCard>
  );
}

export default SocialProfiles;
