import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    infoItemWrapper: {
      display: "flex",
      gap: "16px",
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    value: {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    customFlag: {
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: (props) => props.countryCode ? `url(https://flagcdn.com/w320/${props?.countryCode?.toLowerCase()}.png)` : 'none',
      marginInlineEnd: '8px',
    },
  } 
});
