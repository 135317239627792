export const UnitsLeasePermissions = {
  ViewAndSearchInLeaseUnits: {
    permissionsId: 'ee6dfb36-c77b-46d7-c9eb-08d92ff25bf0',
    permissionsName: 'View and search in Lease units',
    componentsId: null,
    components: null,
  },
  ImportUnits: {
    permissionsId: 'e1d4ee59-b6a8-4293-c9ec-08d92ff25bf0',
    permissionsName: 'Import units',
    componentsId: null,
    components: null,
  },
  UnitBulkUpdate: {
    permissionsId: '599bf1b3-4f7d-4f81-c9ed-08d92ff25bf0',
    permissionsName: 'Unit bulk update',
    componentsId: null,
    components: null,
  },
  AddNewUnit: {
    permissionsId: '896bd745-3695-4208-c9ee-08d92ff25bf0',
    permissionsName: 'Add new unit',
    componentsId: null,
    components: null,
  },
  ViewUnitDetails: {
    permissionsId: '9093e394-b1c1-443d-c9ef-08d92ff25bf0',
    permissionsName: 'View unit details',
    componentsId: null,
    components: null,
  },
  EditUnitDetails: {
    permissionsId: '5a15a907-1039-4829-c9f0-08d92ff25bf0',
    permissionsName: 'Edit unit details',
    componentsId: null,
    components: null,
  },
  ViewUnitImages: {
    permissionsId: '3d9dfbe6-1c30-432e-c9f1-08d92ff25bf0',
    permissionsName: 'View unit images',
    componentsId: null,
    components: null,
  },
  ViewUnitLocationOnMap: {
    permissionsId: 'afd41a48-3f22-4d70-c9f2-08d92ff25bf0',
    permissionsName: 'View unit location on map',
    componentsId: null,
    components: null,
  },
  ViewUnitMarketingInfo: {
    permissionsId: '29c3dfa9-298c-4431-c9f3-08d92ff25bf0',
    permissionsName: 'View unit marketing info',
    componentsId: null,
    components: null,
  },
  EditUnitMarketingInfo: {
    permissionsId: '3387249d-5571-4565-c9f4-08d92ff25bf0',
    permissionsName: 'Edit unit marketing info',
    componentsId: null,
    components: null,
  },
  ViewDocumentsForUnit: {
    permissionsId: '133b71c7-2264-4fe6-c9f5-08d92ff25bf0',
    permissionsName: 'View documents for unit',
    componentsId: null,
    components: null,
  },
  ViewRemarksForUnit: {
    permissionsId: '89762501-f889-4126-c9f6-08d92ff25bf0',
    permissionsName: 'View remarks for unit',
    componentsId: null,
    components: null,
  },
  AddNewRemarksForUnit: {
    permissionsId: '6b1d302b-9524-4067-c9f7-08d92ff25bf0',
    permissionsName: 'Add new remarks for unit',
    componentsId: null,
    components: null,
  },
  UpdateRemarksInUnit: {
    permissionsId: 'e2bf4b9d-3291-4ecc-c9f8-08d92ff25bf0',
    permissionsName: 'Update remarks in unit',
    componentsId: null,
    components: null,
  },
  DeleteRemarksInUnit: {
    permissionsId: 'ff6d2096-4d29-453c-c9f9-08d92ff25bf0',
    permissionsName: 'Delete remarks in unit',
    componentsId: null,
    components: null,
  },
  ViewOpenHouseForUnit: {
    permissionsId: '41e641a7-357d-436e-c9fa-08d92ff25bf0',
    permissionsName: 'View open house for unit',
    componentsId: null,
    components: null,
  },
  AddNewOpenHouseForUnit: {
    permissionsId: '2a0b1718-32ad-4d67-c9fb-08d92ff25bf0',
    permissionsName: 'Add new open house for unit',
    componentsId: null,
    components: null,
  },
  UpdateOpenHouseForUnit: {
    permissionsId: '09292573-aaa6-4764-c9fc-08d92ff25bf0',
    permissionsName: 'Update open house for unit',
    componentsId: null,
    components: null,
  },
  DeleteOpenHouseForUnit: {
    permissionsId: '3802fcea-e783-42b2-c9fd-08d92ff25bf0',
    permissionsName: 'Delete open house for unit',
    componentsId: null,
    components: null,
  },
  ViewTheActivitiesForUnit: {
    permissionsId: '394ae00a-10d3-4e67-c9fe-08d92ff25bf0',
    permissionsName: 'View the activities for unit',
    componentsId: null,
    components: null,
  },
  ViewPaymentDetailsForUnit: {
    permissionsId: '24d51586-ad7c-4bb8-c9ff-08d92ff25bf0',
    permissionsName: 'View Payment details for unit',
    componentsId: null,
    components: null,
  },
  EditPaymentDetailsForUnit: {
    permissionsId: '71e8edbe-d9d0-473b-ca00-08d92ff25bf0',
    permissionsName: 'Edit Payment details for unit',
    componentsId: null,
    components: null,
  },
  ViewParkingInfoForUnit: {
    permissionsId: 'cba8cf75-e9fe-427e-ca01-08d92ff25bf0',
    permissionsName: 'View parking info for unit',
    componentsId: null,
    components: null,
  },
  AddParkingForUnit: {
    permissionsId: '64448679-e400-4a28-ca02-08d92ff25bf0',
    permissionsName: 'Add parking for unit',
    componentsId: null,
    components: null,
  },
  EditParkingForUnit: {
    permissionsId: '67b854b3-6fe3-466a-ca03-08d92ff25bf0',
    permissionsName: 'Edit parking for unit',
    componentsId: null,
    components: null,
  },
  DeleteParkingForUnit: {
    permissionsId: '16830ce6-c09b-4450-ca04-08d92ff25bf0',
    permissionsName: 'Delete parking for unit',
    componentsId: null,
    components: null,
  },
  ViewReferenceDetailsForUnit: {
    permissionsId: 'ebe45a55-ae6a-4279-ca05-08d92ff25bf0',
    permissionsName: 'View Reference details for unit',
    componentsId: null,
    components: null,
  },
  EditReferenceDetailsForUnit: {
    permissionsId: '4b7200a5-0043-43b2-ca06-08d92ff25bf0',
    permissionsName: 'Edit reference details for unit',
    componentsId: null,
    components: null,
  },
  ViewManagemntForUnit: {
    permissionsId: '23bfe515-356e-4279-ca07-08d92ff25bf0',
    permissionsName: 'View Managemnt for unit',
    componentsId: null,
    components: null,
  },
  EditManagemntForUnit: {
    permissionsId: '6b4f98ce-cfc1-4a46-ca08-08d92ff25bf0',
    permissionsName: 'Edit Managemnt for unit',
    componentsId: null,
    components: null,
  },
  ViewPayablesForUnit: {
    permissionsId: '25c00559-34e5-4f9d-ca09-08d92ff25bf0',
    permissionsName: 'View payables for unit',
    componentsId: null,
    components: null,
  },
  EditPayablesForUnit: {
    permissionsId: 'ec711254-7543-4f24-ca0a-08d92ff25bf0',
    permissionsName: 'Edit payables for unit',
    componentsId: null,
    components: null,
  },

  GetAllOwnersByUnitId: {
    permissionsId: '97ec4a09-4c28-468d-e922-08da38d6f417',
    permissionsName: 'Get All Owners By Unit-Id',
    componentsId: null,
    components: null,
  },
  DeleteUnitOwner: {
    permissionsId: 'a23132df-8548-4f51-e923-08da38d6f417',
    permissionsName: 'Delete Unit Owner',
    componentsId: null,
    components: null,
  },
  UpdateUnitOwner: {
    permissionsId: '9540288a-812c-47c7-e924-08da38d6f417',
    permissionsName: 'Update Unit Owner',
    componentsId: null,
    components: null,
  },
  CreateUnitOwner: {
    permissionsId: '5cb5f66e-88a5-41f3-e925-08da38d6f417',
    permissionsName: 'create Unit Owner',
    componentsId: null,
    components: null,
  },

  EditUspMarketing: {
    permissionsId: '312c4a74-0fb2-4bea-85c2-08da4220e9f0',
    permissionsName: 'Edit Usp Marketing',
    componentsId: null,
    components: null,
  },
  EditMarketingTitle: {
    permissionsId: 'd761194d-6875-4aeb-85c3-08da4220e9f0',
    permissionsName: 'Edit Marketing Title',
    componentsId: null,
    components: null,
  },
  EditMarketingDescription: {
    permissionsId: 'ea98b96c-4ff2-4a62-85c4-08da4220e9f0',
    permissionsName: 'Edit Marketing Description',
    componentsId: null,
    components: null,
  },
  EditFeaturedUnitMarketing: {
    permissionsId: 'a6d870e1-2183-40d1-85c5-08da4220e9f0',
    permissionsName: 'Edit Featured Unit Marketing',
    componentsId: null,
    components: null,
  },
  EditHotDealMarketing: {
    permissionsId: 'beeeefdc-e6d3-4f96-85c6-08da4220e9f0',
    permissionsName: 'Edit Hot Deal Marketing',
    componentsId: null,
    components: null,
  },
  EditPublishedToWebsiteMarketing: {
    permissionsId: '167b75c3-8612-43fc-85c7-08da4220e9f0',
    permissionsName: 'Edit Published To Website Marketing',
    componentsId: null,
    components: null,
  },

  MakeTransactionOnUnitLeaseToBeAvailable: {
    permissionsId: 'c77d4252-e1ed-4132-2609-08da55189506',
    permissionsName: 'Make Transaction On Unit Lease To Be Available',
    componentsId: null,
    components: null,
  },
  MakeTransactionOnUnitLeaseToBeDraft: {
    permissionsId: 'b84fdbd8-b396-439a-260a-08da55189506',
    permissionsName: 'Make Transaction On Unit Lease To Be Draft',
    componentsId: null,
    components: null,
  },
  SetUnitAsReserveForLease: {
    permissionsId: '98685e78-fa78-4926-260b-08da55189506',
    permissionsName: 'Set Unit As Reserve For Lease',
    componentsId: null,
    components: null,
  },
  SetUnitAsLease: {
    permissionsId: '131db51f-49b8-42e6-260c-08da55189506',
    permissionsName: 'Set Unit As Lease',
    componentsId: null,
    components: null,
  },

  ViewUnitHistory: {
    permissionsId: '3a6f09f7-3942-406c-fdb5-08dab01d123d',
    permissionsName: 'View Unit History ',
    componentsId: null,
    components: null,
  },
  ViewUnitTemplates: {
    permissionsId: 'e2bcd335-feab-48ed-2888-08dab0da5710',
    permissionsName: 'View Unit Templates',
    componentsId: null,
    components: null,
  },
  ShareUnit: {
    permissionsId: '5baf205e-2d94-4fc8-2889-08dab0da5710',
    permissionsName: 'Share unit',
    componentsId: null,
    components: null,
  },
  ViewUnitMatching: {
    permissionsId: '0d9b8112-eefb-4de0-288a-08dab0da5710',
    permissionsName: 'View Unit Matching',
    componentsId: null,
    components: null,
  },
  ViewTenantLandlordNames: {
    permissionsId: '5df6b2e0-f1e2-4c25-288b-08dab0da5710',
    permissionsName:
      'View Tenant /Landlord names for lease/reserved transactions',
    componentsId: null,
    components: null,
  },

  ViewPricingDetailsForLease: {
    permissionsId: '49bed98a-e650-4cf5-288e-08dab0da5710',
    permissionsName: 'View Pricing Details For Lease/Reserved Transactions',
    componentsId: null,
    components: null,
  },
  ViewInvoicesForLeaseReservedTransactions: {
    permissionsId: 'cc334c19-a12c-4118-288f-08dab0da5710',
    permissionsName: 'View Invoices For Lease/Reserved Transactions',
    componentsId: null,
    components: null,
  },
  EditUnitImageCategory: {
    permissionsId: '22c4e14b-b779-488d-ca94-08dabd6785b7',
    permissionsName: 'Edit Unit Image Category',
    componentsId: null,
    components: null,
  },
  OpenFileForUnitOwner: {
    permissionsId: '6986bd7d-bbd5-418b-ca95-08dabd6785b7',
    permissionsName: 'Open File For Unit Owner',
    componentsId: null,
    components: null,
  },
  OpenUnitTemplate: {
    permissionsId: '39594ec0-12ee-4c86-ca96-08dabd6785b7',
    permissionsName: 'Open Unit Template',
    componentsId: null,
    components: null,
  },
  ViewTransactionsForUnit: {
    permissionsId: 'd5cca31d-6c9a-4763-ca97-08dabd6785b7',
    permissionsName: 'View Transactions For Unit',
    componentsId: null,
    components: null,
  },
  ViewTransactionDetailsForUnit: {
    permissionsId: 'ece272b0-528f-4845-ca98-08dabd6785b7',
    permissionsName: 'View Transaction Details For Unit',
    componentsId: null,
    components: null,
  },
  ViewReminderForLeasedReservedTransaction: {
    permissionsId: '1c50b470-a29d-4626-ca99-08dabd6785b7',
    permissionsName: 'View Reminder For Leased/Reserved Transaction',
    componentsId: null,
    components: null,
  },
  AddReminderForLeasedReservedTransaction: {
    permissionsId: '1c46a034-9782-4992-ca9a-08dabd6785b7',
    permissionsName: 'Add Reminder For Leased/Reserved Transaction',
    componentsId: null,
    components: null,
  },
  AddDocumentsForUnits: {
    permissionsId: '32d330ee-9db6-43c0-2e57-08db604be055',
    permissionsName: 'Add Documents For Units',
    description: null,
    componentsId: null,
    components: null,
  },
  EditDocumentsForUnits: {
    permissionsId: 'a664938d-5a82-42f8-2e58-08db604be055',
    permissionsName: 'Edit Documents For Units',
    description: null,
    componentsId: null,
    components: null,
  },
  DeleteDocumentsFromUnits: {
    permissionsId: '11bbc957-fe7d-4b77-2e59-08db604be055',
    permissionsName: 'Delete Documents From Units',
    description: null,
    componentsId: null,
    components: null,
  },
  ShareUnitDocuments: {
    permissionsId: 'f51733d5-8f2a-4f2d-c4b2-08dc683b0c43',
    permissionsName: 'Share Unit Documents',
    description: null,
    componentsId: null,
    components: null,
  },

  LeaseListingUnitDubizzle: {
    permissionsId: 'a3e514ab-7ab6-4f76-a0c1-08db9980f187',
    permissionsName: 'Lease Listing Unit / Dubizzle',
    description: null,
    componentsId: null,
    components: null,
  },

  LeaseListingUnitBayut: {
    permissionsId: '0ab199f9-b88e-4d7f-a0c2-08db9980f187',
    permissionsName: 'Lease Listing Unit / Bayut',
    description: null,
    componentsId: null,
    components: null,
  },

  LeaseListingUnitPropertyFinder: {
    permissionsId: 'db6e5d19-8808-42c4-a0c3-08db9980f187',
    permissionsName: 'Lease Listing Unit / Property Finder',
    description: null,
    componentsId: null,
    components: null,
  },

  LeaseListingUnitHouza: {
    permissionsId: '4b505ce4-f264-469a-a0c4-08db9980f187',
    permissionsName: 'Lease Listing Unit / Houza',
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewActivity: {
    permissionsId: '182c13f7-df26-4dab-2871-08dbaf79a1bb',
    permissionsName: 'Add New Activity',
    description: null,
    componentsId: null,
    components: null,
  },
  EditReservedLeaseTransaction: {
    permissionsId: '7317156d-3698-41c3-2b19-08dbca2fdea8',
    permissionsName: 'Edit Reserved Lease Transaction',
    description: null,
    componentsId: null,
    components: null,
  },
  AddPublishedUnit: {
    permissionsId: '4beaeb45-7a51-40a0-7040-08dbf65badbd',
    permissionsName: 'Add Published Unit',
    description: null,
    componentsId: null,
    components: null,
  },
  ShowWebPortals: {
    permissionsId: 'f7e67a31-c707-4507-7041-08dbf65badbd',
    permissionsName: 'Show Web Portals',
    description: null,
    componentsId: null,
    components: null,
  },
  EditListingAgent: {
    permissionsId: '8eba63c2-185b-479e-42f0-08dc2f124e17',
    permissionsName: 'Edit Listing Agent',
    description: null,
    componentsId: null,
    components: null,
  },
  EditLeaseListingAgent: {
    permissionsId: '91037d60-8196-4d91-42f1-08dc2f124e17',
    permissionsName: 'Edit Lease Listing Agent',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTasksForUnit: {
    permissionsId: '352134e6-7f4a-47be-19b8-08dc313da57c',
    permissionsName: 'View Tasks For Unit',
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewTask: {
    permissionsId: 'bae5e9e1-cb23-48f3-19b9-08dc313da57c',
    permissionsName: 'Add New Task',
    description: null,
    componentsId: null,
    components: null,
  },
  EditListingAgent: {
    permissionsId: '8eba63c2-185b-479e-42f0-08dc2f124e17',
    permissionsName: 'Edit Listing Agent',
    description: null,
    componentsId: null,
    components: null,
  },
  EditLeaseListingAgent: {
    permissionsId: '91037d60-8196-4d91-42f1-08dc2f124e17',
    permissionsName: 'Edit Lease Listing Agent',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTasksForUnit: {
    permissionsId: '352134e6-7f4a-47be-19b8-08dc313da57c',
    permissionsName: 'View Tasks For Unit',
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewTask: {
    permissionsId: 'bae5e9e1-cb23-48f3-19b9-08dc313da57c',
    permissionsName: 'Add New Task',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewUnitMPI: {
    permissionsId: 'b3692ffe-3589-4f01-f46b-08dc3da1d8ed',
    permissionsName: 'View Unit MPI',
    description: null,
    componentsId: null,
    components: null,
  },
  UnitDuplications : {
    permissionsId: "8e024541-0495-4ac6-f476-08dc3da1d8ed",
    permissionsName: "Unit Duplications",
    description: null,
    componentsId: null,
    components: null
  },
  ShareUnits : {
    permissionsId: "c7c11430-0e2b-4d11-5dcc-08dc7403ebc2",
    permissionsName: "Share Unit",
    description: null,
    componentsId: null,
    components: null
  },
  ProposalHistory : {
    permissionsId: "e8f956d0-bb23-47a1-9f80-08dc85eb760a",
    permissionsName: "Proposal History",
    description: null,
    componentsId: null,
    components: null
  },
  ViewTenantTab :  {
     permissionsId: "97465c81-0d09-469c-7957-08dcaf947ea8",
     permissionsName: "View Tenant Tab",
     description: null,
     componentsId: null,
     components: null
}
};
