import { TemplatesView } from '../../Views/Home';
import { TeamplateOpenFolder } from '../../Views/Home/TemplatesView/TeamplateOpenFolder';

export const TemplatesRoutes = [
  {
    path: '/view',
    name: 'TemplatesView:templates-builder',
    component: TemplatesView,
    layout: '/home/templates',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TemplatesView:templates-builder',
        isDisabled: true,
        route: '/home/templates/view',
        groupName: 'system-parameters',
      },
    ],
  }, {
    path: '/OpenFile',
    name: 'TemplatesView:templates-builder',
    component: TeamplateOpenFolder,
    layout: '/home/templates',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'TemplatesView:templates-builder',
        isDisabled: false,
        route: '/home/templates/view',
        groupName: 'system-parameters',
      }, {
        name: 'TemplatesView:OpenFile-templates',
        isDisabled: false,
        route: '/home/templates/OpenFile',
      },
    ],
  },
];
