import React from "react";
import PropTypes from "prop-types";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// styles
import useStyles from "./styles";

function LayoutContent({ children }) {
  const { mainContainerPadding } = useVerticalNav();
  const styles = useStyles({ mainContainerPadding });

  return (
    <main className={styles.root}>
      {children}
    </main>
  );
}

LayoutContent.propTypes = {
  children: PropTypes.node,
};

LayoutContent.defaultProps = {
  children: null,
};

export default LayoutContent;
