import React, { useState } from "react";
import clsx from "clsx";
import { List, ListItem, ListItemIcon } from "@material-ui/core";
import { useIsActiveRoute } from "../../../Hooks";
import { PermissionsComponent } from "../..";
import { MainMenu, NewHomeRoutes } from "../../../routes";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Icons
import { Adjust, RadioButtonUnchecked } from "@material-ui/icons";

// UI
import { ExpandedSideNav } from "./UI";

// Styles
import useStyles from "./styles";

function SideNav() {
  const [menu] = useState(MainMenu);
  const [open, setOpen] = useState(null);

  const { isActiveRoute } = useIsActiveRoute();

  const {
    isExpanded,
    onTogglePinned,
    setHoverExpanded,
    hoverExpanded,
    sideNavWidth,
    navNotCollapsed,
  } = useVerticalNav();

  const styles = useStyles({
    isExpanded,
    hoverExpanded,
    sideNavWidth,
    navNotCollapsed,
  });

  const handleMouseEnter = () => {
    if (!isExpanded) {
      setHoverExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    setHoverExpanded(false);
  };

  const getFullMenu = () => {
    return menu.map((menuItem) => ({
      ...menuItem,
      children: NewHomeRoutes.filter(
        (homeRoute) => homeRoute.groupId === menuItem.groupId
      ),
    }));
  };

  const handleMenuToggle = (id) => {
    setOpen((prevOpen) => (prevOpen === id ? null : id));
  };

  return (
    <aside
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx(styles.sideNav, !navNotCollapsed && styles.hideScrollbar)}
    >
      <div className={styles.pinIconContainer}>
        {navNotCollapsed && (
          <>
            {isExpanded ? (
              <Adjust  style={{ color: '#94969C' }} onClick={onTogglePinned} />
            ) : (
              <RadioButtonUnchecked  style={{ color: '#94969C' }} onClick={onTogglePinned} />
            )}
          </>
        )}
      </div>

      <List component="nav">
        {getFullMenu()
          .sort((a, b) => a.order - b.order)
          .map((item, index) => {
            if (navNotCollapsed) {
              return (
                <ExpandedSideNav 
                  item={item} 
                  index={index} 
                  handleMenuToggle={handleMenuToggle}
                  open={open} 
                  defaultStyles={styles} 
                />
              );
            } else {
              return (
                <PermissionsComponent
                  permissionsList={item.roles}
                  allowEmptyRoles
                  key={index}
                >
                  <ListItem
                    key={index}
                    className={clsx(
                      styles.colNavIconWrapper,
                      isActiveRoute(item) && styles.ActiveListItemWrapper
                    )}
                  >
                    <ListItemIcon className={styles.listItemIcon}>
                      <span
                        className={clsx(
                          item.newIcon,
                          styles.navIcon,
                          isActiveRoute(item) && styles.activeNavIcon
                        )}
                      ></span>
                    </ListItemIcon>
                  </ListItem>
                </PermissionsComponent>
              );
            }
          })}
      </List>
    </aside>
  );
}

export default SideNav;
